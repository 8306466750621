import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const RecipeListToolbar = ({ viewType, setViewType, searchRecipes, recipes, loading }) => {
  const [searchString, setSearchString] = useState('')
  const { t } = useTranslation()

  const onInputChange = (value) => {
    setSearchString(value)
    searchRecipes(value)
  }

  const setView = (type) => {
    setViewType(type)
    window.localStorage.setItem('viewtype', type)
  }

  useEffect(() => {
    let type = window.localStorage.getItem('viewtype')
    if (!type) type = 'gallery'
    setViewType(type)
    window.localStorage.setItem('viewtype', type)
  }, [setViewType])

  return (
    <nav className="toolbar recipe-toolbar">
      <div className="toolbar-start">
        <div className="toolbar-item">
          <div className="buttons has-addons">
            <p className="control">
              <button
                className={`button ${
                  viewType === 'gallery' ? 'is-selected is-primary is-light' : 'is-white'
                }`}
                onClick={() => setView('gallery')}
                id="setViewGallery">
                <span className="icon is-small">
                  <i className="fas fa-th-large"></i>
                </span>
              </button>
            </p>
            <p className="control">
              <button
                className={`button ${
                  viewType === 'table' ? 'is-selected is-primary is-light' : 'is-white'
                }`}
                onClick={() => setView('table')}
                id="setViewTable">
                <span className="icon is-small">
                  <i className="fas fa-align-justify"></i>
                </span>
              </button>
            </p>
          </div>
        </div>

        <div className="toolbar-item">
          <div className="field">
            <div
              className={`control has-icons-left toolbar__search ${loading ? 'is-loading' : ''}`}>
              <input
                className="input is-rounded"
                type="search"
                placeholder={t('Find a recipe')}
                value={searchString}
                onChange={(e) => onInputChange(e.target.value)}
                autoComplete="none"
                autoCapitalize="none"
              />
              <span className="icon is-left">
                <i className="fas fa-search"></i>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="toolbar-end">
        <div className="toolbar-item is-hidden-mobile">
          {!loading && <strong>{t('recipe', { count: recipes.length })}</strong>}
        </div>
      </div>
    </nav>
  )
}

export { RecipeListToolbar as default }
