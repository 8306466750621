import React from 'react'
import { useTranslation } from 'react-i18next'
import { nl2br, formatTime } from '../../utils/helpers'

const RecipeIntro = ({ recipe }) => {
  const { t } = useTranslation()

  return (
    <div className="recipe__intro recipe--mobile-padding">
      <h1 className="recipe__title title is-2">{recipe.title}</h1>
      <div className="recipe__description">{nl2br(recipe.description)}</div>
      {recipe.hasOwnProperty('time') && Object.keys(recipe.time).length > 0 && (
        <div className="recipe__times columns is-mobile">
          {!!recipe.time.prepTime && (
            <div className="recipe__time column">
              <div className="recipe__time_title">{t('Prep')}</div>
              <div className="recipe__time_content">{formatTime(recipe.time.prepTime)}</div>
            </div>
          )}
          {!!recipe.time.cookTime && (
            <div className="recipe__time column">
              <div className="recipe__time_title">{t('Cook')}</div>
              <div className="recipe__time_content">{formatTime(recipe.time.cookTime)}</div>
            </div>
          )}
          {!!recipe.time.totalTime && (
            <div className="recipe__time column">
              <div className="recipe__time_title">{t('Total')}</div>
              <div className="recipe__time_content">{formatTime(recipe.time.totalTime)}</div>
            </div>
          )}
        </div>
      )}

      {recipe.sourceUrl && (
        <div className="recipe__source">
          <div className="recipe__time_title">{t('Source')}</div>
          <div>
            <a target="blank" href={recipe.sourceUrl}>
              {recipe.sourceDomain}
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

export { RecipeIntro as default }
