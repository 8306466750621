import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Sidebar = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const [addRecipeOpen, setAddRecipeOpen] = useState(false)

  const onAddRecipeClick = () => {
    setAddRecipeOpen(!addRecipeOpen)
  }

  let page
  if (location.pathname.indexOf('/settings') !== -1) {
    page = 'settings'
  } else if (location.pathname.indexOf('/recipes') !== -1 || location.pathname === '/') {
    page = 'recipes'
  }

  useEffect(() => {
    // close dropdown when user clicks outside
    const dropdown = document.getElementById('dropdown')
    const closeDropdown = (e) => {
      if (!e.relatedTarget || e.relatedTarget.className !== 'dropdown-item') {
        setAddRecipeOpen(false)
      }
    }
    dropdown.addEventListener('focusout', closeDropdown)
    return () => dropdown.removeEventListener('focusout', closeDropdown)
  }, [])
  return (
    <div className="sidebar">
      <div className="sidebar__logo app-logo">
        <Link to={'/'}>
          <img src="/images/bergamot-logo.svg" alt="Bergamot App logo" />
        </Link>
      </div>

      <div
        id="dropdown"
        className={`sidebar__dropdown dropdown center ${addRecipeOpen ? 'is-active' : ''}`}>
        <div className="dropdown-trigger">
          <button
            onClick={onAddRecipeClick}
            id="add-recipe-button"
            className="sidebar__add-button"
            aria-haspopup="true"
            aria-controls="dropdown-sidebar">
            <div className="icon-left">
              <span className="icon sidebar__add-button__icon">
                <i className="fas fa-plus"></i>
              </span>
              <span className="sidebar__add-button__text">{t('Add a recipe')}</span>
            </div>
            <div className="icon-right">
              <span className="icon is-small sidebar__add-button__arrow ">
                <i className="fas fa-angle-down" aria-hidden="true"></i>
              </span>
            </div>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-sidebar" role="menu">
          <div className="dropdown-content">
            <Link to={'/import'} className="dropdown-item">
              <span className="icon">
                <i className="fas fa-link" aria-hidden="true"></i>
              </span>
              <span>{t('Import a recipe')}</span>
            </Link>
            <Link to={'/recipes/edit'} className="dropdown-item">
              <span className="icon">
                <i className="fas fa-pen" aria-hidden="true"></i>
              </span>
              <span>{t('Write a new recipe')}</span>
            </Link>
          </div>
        </div>
      </div>

      <aside className="menu sidebar__menu">
        <ul className="menu-list">
          <li>
            <Link className={page === 'recipes' ? 'is-active' : ''} to="/">
              <span className="icon is-medium">
                <i className="far fa-lg fa-lemon"></i>
              </span>
              <span className="menu-entry">{t('My Recipes')}</span>
            </Link>
          </li>
          <li>
            <Link className={page === 'settings' ? 'is-active' : ''} to="/settings">
              <span className="icon is-medium">
                <i className="fas fa-lg fa-cog"></i>
              </span>
              <span className="menu-entry">{t('Settings')}</span>
            </Link>
          </li>
        </ul>
      </aside>
    </div>
  )
}

export { Sidebar as default }
