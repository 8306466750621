import React from 'react'

const changeImageSize = (url, width, height) => {
  if (url.indexOf('https://aihkimhfpo.cloudimg.io/') === -1) {
    return url
  }
  const baseUrl = url.substr(0, url.indexOf('?'))
  return `${baseUrl}?w=${width}&h=${height}`
}

/******************************
 * TEXT FORMATING
 */

const strongQuantitiesRegex = (str, callback) => {
  // (?<![a-z1-9]) only if not before a letter ex: T50
  return str.replace(/(?:^|\W?)([0-9]+[,.]?[0-9]*)/gim, callback)
}

const strongQuantities = (str) => {
  // (?<![a-z1-9]) only if not before a letter ex: T50
  return strongQuantitiesRegex(str, (a, b) => {
    return a.replace(b, '<strong>' + b + '</strong>')
  })
}

const fractionQuantities = (str) => {
  return str.replace(/(0\.25|0\.5|0\.75)/gim, (a) => {
    const float2Unicode = {
      0.25: '¼',
      0.5: '½',
      0.75: '¾',
    }
    return float2Unicode[a]
  })
}

const strongUnits = (str) => {
  // [\d\s] if after a space of a number
  // (?:$|[\s/()-.]) if before a space one of these '\s/()-.' of end of line
  return str.replace(
    /[\d\s](g|grammes?|kgs?|mls?|cls?|l|litres?|tasses?|pinc[eéè]e?s?|(?:cuill[eéè]res?|c|cuill?)[.]? ?[àa]?[.]? ?(?:soupe|s|caf[eéè]|c|th[eéè]).?|tsps?|teaspoons?|tbsps?|tablespoons?|cups?|csps?|ounces?|lb|fl|oz)(?:$|[\s/()-.])/gim,
    (a, b) => {
      return a.replace(b, '<strong>' + b + '</strong>')
    },
  )
}

const formatIngredients = (str) => {
  str = strongUnits(str)
  str = strongQuantities(str)
  str = fractionQuantities(str)
  return str
}

const nl2br = (str) => {
  if (typeof str !== 'string') return str
  return str.split('\n').map((item, index) => {
    return index === 0 ? item : [<br />, item]
  })
}

/**
 * formatTime
 * Format time in a readable string
 * @param {number} time
 * @param {type} type 's' for short
 */
const formatTime = (time, type = '') => {
  const h = parseInt(time / 60)
  const m = parseInt(time % 60)

  if (!h && !m) {
    return '-'
  }

  if (type === 's') {
    const hStr = h ? `${h}h` : ''
    let mStr = m ? `${('0' + m).slice(-2)}` : ''
    mStr = !h && m ? mStr + 'min' : mStr
    return `${hStr}${mStr}`.trim()
  } else {
    const hStr = h ? formatPlural(h, 'hr') : ''
    const mStr = m ? (h ? m : formatPlural(m, 'min')) : ''
    return `${hStr} ${mStr}`.trim()
  }
}

const formatPlural = (number, string) => {
  const s = number > 1 ? 's' : ''
  return `${number} ${string}${s}`
}

/******************************
 * FORM DATA CONVERSIONS
 */

const instructionsToStr = (data, emptyLines = 1) => {
  if (!Array.isArray(data)) {
    return ''
  }
  let str = ''
  let emptyLinesStr = ''
  for (let i = 0; i < emptyLines; i++) {
    emptyLinesStr = emptyLinesStr.concat('\n')
  }
  data.forEach((sublist, i) => {
    if (sublist.hasOwnProperty('title') && sublist.title) {
      // add space before title unless it's the first line
      str += i !== 0 ? emptyLinesStr : ''
      // add title with ':'
      str += sublist.title + ':' + emptyLinesStr
    }
    sublist.data.forEach((lineStr) => {
      str += lineStr + emptyLinesStr
    })
  })
  return str
}

const strToInstructions = (str) => {
  if (str.trim() === '') {
    return []
  }
  let linesArray = str.trim().split('\n')
  let sublistIndex = 0
  let instructions = [{ data: [] }]
  linesArray.forEach((line) => {
    if (line.length && line.trim().lastIndexOf(':') === line.length - 1) {
      sublistIndex++
      instructions[sublistIndex] = {}
      instructions[sublistIndex].title = line.slice(0, line.length - 1).trim()
      instructions[sublistIndex].data = []
    } else if (line.length) {
      instructions[sublistIndex].data.push(line.trim())
    }
  })
  instructions = instructions.filter(
    (instruction) => instruction !== undefined && instruction.data.length > 0,
  )
  return instructions
}

const indexToTimeArray = (index) => {
  let value
  index = parseInt(index)
  if (index < 20) value = parseInt(index)
  else if (index < 40) value = 20 + (index - 20) * 5
  else value = 120 + (index - 40) * 15

  const label = value ? formatTime(value) : '0 min'

  const timeArray = { index, value, label }
  return timeArray
}

const valueToTimeArray = (value) => {
  value = isNaN(parseInt(value)) ? 0 : value
  const min01Steps = value >= 20 ? 20 : value
  const min05Steps = value - min01Steps >= 100 ? 100 / 5 : (value - min01Steps) / 5
  const min15Steps = (value - min01Steps - min05Steps * 5) / 15
  const index = Math.round(min01Steps + min05Steps + min15Steps)
  return indexToTimeArray(index)
}

/******************************
 * SCROLL POSITION
 */
const saveScrollPosition = () => {
  window.sessionStorage.setItem('xListScroll', window.scrollX)
  window.sessionStorage.setItem('yListScroll', window.scrollY)
}

const restoreScrollPosition = () => {
  window.scrollTo(
    window.sessionStorage.getItem('xListScroll'),
    window.sessionStorage.getItem('yListScroll'),
  )
  sessionStorage.removeItem('xListScroll')
  sessionStorage.removeItem('yListScroll')
}

/******************************
 * OTHERS
 */
const isMobile = () => {
  return window.innerWidth < 769
}

export {
  changeImageSize,
  strongUnits,
  strongQuantitiesRegex,
  strongQuantities,
  formatIngredients,
  nl2br,
  formatTime,
  formatPlural,
  instructionsToStr,
  strToInstructions,
  indexToTimeArray,
  valueToTimeArray,
  saveScrollPosition,
  restoreScrollPosition,
  isMobile,
}
