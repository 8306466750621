import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useAuth } from '../../context/auth-context'
import * as userClient from '../../utils/api/user-client'
import { addSharedRecipe } from '../../utils/api/recipe-client'
import { useTranslation, Trans } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import InputPassword from '../Common/InputPassword'

const SigninForm = ({ showPasswordRecoveryForm }) => {
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const { login } = useAuth()
  const { register, handleSubmit, setValue, errors } = useForm()

  const [loginFailed, setLoginFailed] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = async (formData) => {
    setLoading(true)
    const token = await userClient.login(formData)
    if (token) {
      login(token)
      const recipeId = await addSharedRecipe()
      if (!recipeId) {
        history.push('/')
      } else {
        history.push({ pathname: `/recipes/${recipeId}`, state: { recipeSharedAdded: true } })
      }
    } else {
      setLoading(false)
      setLoginFailed(true)
    }
  }

  useEffect(() => {
    // Get email value to prefill email input
    if (location.state && location.state.email) {
      setValue([{ email: location.state.email }])
    }
  }, [location.state, setValue])

  return (
    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
      {loginFailed && (
        <article className="message is-danger">
          <div className="message-body">
            <Trans i18nKey="error.password_ko">
              Sorry, that password isn't right. We can help you{' '}
              <a onClick={showPasswordRecoveryForm} href="#">
                recover your password
              </a>
              .
            </Trans>
          </div>
        </article>
      )}

      <div className="field">
        <div className="control has-icons-left">
          <TextField
            label={t('Email')}
            name="email"
            variant="outlined"
            size="small"
            fullWidth={true}
            className=""
            inputRef={register({
              required: {
                value: true,
                message: t('Please provide an email to login'),
              },
              pattern: {
                value: /^.+@[^.].*\.[a-z]{2,}$/i,
                message: t('This is not a valid email address'),
              },
            })}
            inputProps={{
              autoCapitalize: 'none',
              autoCorrect: 'off',
            }}
          />
          <span className="icon is-small is-left">
            <i className="fas fa-envelope"></i>
          </span>
        </div>
        {errors.email && <p className="help is-danger">{errors.email.message}</p>}
      </div>
      <div className="field">
        <InputPassword
          variant="outlined"
          size="small"
          className=""
          inputRef={register({
            required: {
              value: true,
              message: t("You didn't give any password !"),
            },
          })}
          fullWidth={true}
        />
        {errors.password && <p className="help is-danger">{errors.password.message}</p>}
      </div>

      <div className="field">
        <button
          type="submit"
          className={`button is-rounded is-link ${loading ? 'is-loading' : ''}`}>
          {t('Log In')}
        </button>
      </div>
    </form>
  )
}

export { SigninForm as default }
