import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Sidebar = () => {
  const { t } = useTranslation()
  const location = useLocation()

  // const [addRecipeOpen, setAddRecipeOpen] = useState(false)

  // const onAddRecipeClick = () => {
  //   setAddRecipeOpen(!addRecipeOpen)
  // }

  let page
  if (location.pathname.indexOf('/settings') !== -1) {
    page = 'settings'
  } else if (location.pathname.indexOf('/recipes') !== -1 || location.pathname === '/') {
    page = 'recipes'
  }

  return (
    <nav className="tabbar is-hidden-tablet">
      <div className="tabbar__wrapper">
        <div className="tabbar__item">
          <Link className={page === 'recipes' ? 'is-active' : ''} to="/">
            <div className="icon is-medium">
              <i className="far fa-lg fa-lemon  icon-shadow"></i>
            </div>
            <div className="tabbar__item-label">{t('My Recipes')}</div>
          </Link>
        </div>
        <div className="tabbar__item">
          <Link to={'/import'}>
            <div className="tabbar__add-icon">
              <span className="fa-stack fa-2x icon-shadow">
                <i className="fas fa-circle fa-stack-2x icon-shadow"></i>
                <i className="fas fa-plus fa-stack-1x fa-inverse"></i>
              </span>
            </div>
          </Link>
        </div>

        <div className="tabbar__item">
          <Link className={page === 'settings' ? 'is-active' : ''} to="/settings">
            <div className="icon is-medium">
              <i className="fas fa-lg fa-cog"></i>
            </div>
            <div className="tabbar__item-label">{t('Settings')}</div>
          </Link>
        </div>
      </div>
    </nav>
  )
}

export { Sidebar as default }
