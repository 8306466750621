import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'

const InputPassword = (props) => {
  const { t } = useTranslation()

  const [passVisible, setPassVisible] = useState(false)
  const togglePassword = () => {
    console.log('togglePassword', passVisible)
    setPassVisible(!passVisible)
  }

  return (
    <div className="control has-icons-left has-icons-right">
      <TextField
        label={props?.label ? props?.label : t('Password')}
        name={props?.name ? props?.name : 'password'}
        type={passVisible ? 'text' : 'password'}
        inputProps={{
          autoComplete: 'off',
          autoCapitalize: 'none',
          autoCorrect: 'off',
        }}
        {...props}
      />
      <span className="icon is-small is-left">
        <i className="fas fa-lock"></i>
      </span>
      <div onClick={togglePassword}>
        {passVisible ? (
          <span className="icon is-small is-right show-password" key="show-pass">
            <i className="fas fa-eye"></i>
          </span>
        ) : (
          <span className="icon is-small is-right show-password" key="not-show-pass">
            <i className="fas fa-eye-slash"></i>
          </span>
        )}
      </div>
    </div>
  )
}

export { InputPassword as default }
