import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../context/auth-context'
import PrivatePageLayout from './../components/PrivatePageLayout'
import pathToTitle from '../utils/pathToTitle'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuth()
  const isAuthenticated = Object.keys(user).length > 0

  useEffect(() => {
    pathToTitle(rest.path)
  }, [rest.path])

  return (
    <PrivatePageLayout>
      <Route
        {...rest}
        component={(props) =>
          isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
        }
      />
    </PrivatePageLayout>
  )
}

export { PrivateRoute as default }
