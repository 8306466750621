import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translations_fr from './locales/fr/translation.json'
import translations_en from './locales/en/translation.json'

import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options

  .init({
    debug: true,
    // lng: 'en',
    fallbackLng: 'en',
    resources: {
      en: {
        translation: translations_en,
      },
      fr: {
        translation: translations_fr,
      },
    },
    detection: {
      // I want to guess from:
      //   - querystring first (when having in url ?lng=fr)
      //   - or navigator (the browser Accept-Language I guess)
      order: ['querystring', 'navigator'],

      // You can change the query string here to customize your urls
      lookupQuerystring: 'lng',
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
