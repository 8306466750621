import React from 'react'
import RecipeImportForm from './RecipeImportForm'
import RecipeImportSuggestions from './RecipeImportSuggestions'
import { useTranslation } from 'react-i18next'

const RecipeImport = () => {
  const { t } = useTranslation()
  return (
    <div className="recipe-importer">
      <div className="default-layout">
        <section className="recipe-importer__form">
          <div className="default-layout__wrap">
            <h1 className="title">{t('Import a recipe')}</h1>
            <article className="message is-primary">
              <div className="message-body">
                <strong>{t('Import a recipe from any website')}</strong>
                <br />
                {t('Paste the URL of the recipe webpage here a click on the import button !')}
              </div>
            </article>
            <RecipeImportForm />
          </div>
        </section>
        <section className="bg-2 recipe-importer__suggestions">
          <div className="default-layout__wrap default-layout__wrap-limited">
            <div className="title is-4">{t('Recipe websites suggestions')}</div>
            <RecipeImportSuggestions />
          </div>
        </section>
      </div>
    </div>
  )
}

export { RecipeImport as default }
