import React, { useState, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { formatTime, saveScrollPosition } from '../../utils/helpers'
import { addFavorite, deleteFavorite } from '../../utils/api/recipe-client'

const RecipeListItem = ({ recipe }) => {
  let location = useLocation()
  const id = recipe.id

  const [isFavorite, setIsFavorite] = useState(Boolean(recipe.userFavorite))

  const toggleFavorite = (e) => {
    // prevent click on recipe link
    e.preventDefault()
    if (isFavorite) deleteFavorite(id)
    else addFavorite(id)
    setIsFavorite(!isFavorite)
  }

  const photoSrc = useMemo(() => {
    if (recipe.photos && recipe.photos[0]) {
      return recipe.photos[0].photoThumbUrl
    } else {
      return `/images/placeholder-1200x800-${recipe.id % 4}.png`
    }
  }, [recipe.id, recipe.photos])

  return (
    <Link
      className="recipe-list-item"
      onClick={saveScrollPosition}
      to={{ pathname: `/recipes/${recipe.id}`, state: { recipe, from: location.pathname } }}>
      <div className="recipe-list-item__card">
        <figure className="recipe-list-item__card-image">
          <div
            className="img"
            style={{ background: `center / cover no-repeat url('${photoSrc}')` }}></div>
          {isFavorite ? (
            <button className="recipe-list-item__favorite">
              <span
                className="icon has-text-primary"
                key="is-favorite"
                onClick={toggleFavorite.bind(this)}>
                <i className="fas fa-heart"></i>
              </span>
            </button>
          ) : (
            <button className="recipe-list-item__favorite">
              <span className="icon" key="is-not-favorite" onClick={toggleFavorite.bind(this)}>
                <i className="far fa-heart"></i>
              </span>
            </button>
          )}
          {!!recipe.time && !!recipe.time.totalTime && (
            <span className="tag is-primary recipe-list-item__card-time">
              <i className="far fa-clock"></i>
              <span>&nbsp;</span>
              {formatTime(recipe.time.totalTime, 's')}
            </span>
          )}
        </figure>

        <div className="recipe-list-item__card-content">
          <span className="h2">{recipe.title}</span>
        </div>
      </div>
    </Link>
  )
}

export { RecipeListItem as default }
