import React from 'react'
import { useHistory } from 'react-router-dom'
import BubbleLoader from '../BubbleLoader'
import { useTranslation } from 'react-i18next'

const RecipeToolbar = ({ loading, title }) => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <nav className="toolbar recipe-toolbar">
      <div className="toolbar-start">
        <div className="toolbar-item">
          <button
            className="button button--back is-icon is-primary is-light is-rounded"
            onClick={() => history.goBack()}>
            <span className="icon">
              <i className="fas fa-chevron-left icon-size-m"></i>
            </span>
          </button>
        </div>
      </div>

      <div className="toolbar-end">
        <div className="toolbar-item">
          {loading ? (
            <BubbleLoader />
          ) : (
            <div className="field">
              <div className="control">
                <button
                  className="button is-primary is-rounded"
                  type="submit"
                  form="formRecipeEdit">
                  {t('Save Recipe')}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  )
}

export { RecipeToolbar as default }
