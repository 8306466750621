import React, { useEffect, useState } from 'react'
import ReactS3Uploader from 'react-s3-uploader'
import * as userClient from '../../utils/api/user-client'

const RecipeUploadPhoto = ({ recipeId, photos, setPhotos }) => {
  const [uploading, setUploading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const preloadImage = (src) => {
    // preload image and render
    var downloadingImage = new Image()
    downloadingImage.src = src
    downloadingImage.onload = function () {
      setLoading(false)
    }
  }

  let url = ''
  let filename = ''
  let fileInput // fileInput ref

  // ensure recipeId=1 for placeholder picture
  recipeId = recipeId ? recipeId : 1

  const onUploadStart = (file, next) => {
    setLoading(true)
    // check file extention
    const allowedExtentions = ['.jpg', '.jpeg', '.png']
    const extention = file.name.slice(file.name.lastIndexOf('.'))
    if (allowedExtentions.indexOf(extention) === -1) {
      setError(`Please choose an image (${allowedExtentions.join(', ')})`)
    } else {
      resizeImage(file, next, { format: 'image/jpeg' })
    }

    // resizeImage using canvas
    function resizeImage(file, next, options) {
      function dataURItoBlob(dataURI, format) {
        const binary = atob(dataURI.split(',')[1])
        const array = []
        for (let i = 0; i < binary.length; i++) {
          // eslint-disable-line
          array.push(binary.charCodeAt(i))
        }
        return new Blob([new Uint8Array(array)], { type: format })
      }

      const { format } = options
      const img = new Image()
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      img.onload = () => {
        const { width, height } = img
        const max = 1200
        if (width > height) {
          canvas.width = max
          canvas.height = (max * height) / width
        } else {
          canvas.width = (max * width) / height
          canvas.height = max
        }
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
        next(new File([dataURItoBlob(canvas.toDataURL(format, 0.5))], file.name, { type: format }))
      }
      img.src = URL.createObjectURL(file)
    }
  }

  const onSignedUrl = (signingServerResponse) => {
    // get photo temporary url and filename
    const signedUrl = signingServerResponse.signedUrl
    url = signedUrl.slice(0, signedUrl.indexOf('?'))
    filename = url.slice(url.lastIndexOf('/'))
  }

  const onProgress = (percent, message, file) => {
    // update progress bar
    //setProgress(percent)
  }

  const onUploadError = (message) => {
    setError(message)
  }

  const onUploadFinish = (signResult) => {
    setPhotos([{ url, filename }])
    setUploading(false)
    setLoading(true)
    preloadImage(url)
  }

  useEffect(() => {
    if (photos.length) {
      setLoading(true)
      preloadImage(photos[0].url)
    }
  }, [photos])

  return (
    <div className="field photo-upload" onClick={() => fileInput.click()}>
      <div className="control">
        <div className="photo-upload__preview">
          {photos.length ? (
            loading ? (
              <div className="loader-wrapper">
                <div className="loader is-loading"></div>
              </div>
            ) : (
              <img src={photos[0].url} alt="recipe" />
            )
          ) : (
            <img
              src={`/images/placeholder-1200x800-${recipeId % 4}.png`}
              alt="recipe placeholder"
            />
          )}
          <div className="photo-upload__icon">
            <i className="fas fa-plus-circle fa-lg"></i>
          </div>
        </div>
        <div className="is-invisible">
          <div className="file is-boxed">
            <label className={`file-label ${uploading ? 'disabled' : ''}`}>
              <ReactS3Uploader
                id="file-input"
                inputRef={(cnt) => (fileInput = cnt)}
                className="file-input photo-upload__file-input"
                disabled={uploading ? true : false}
                signingUrl="/s3/sign"
                signingUrlMethod="GET"
                accept="image/*"
                s3path="uploads/"
                preprocess={onUploadStart}
                onSignedUrl={onSignedUrl}
                onProgress={onProgress}
                onError={onUploadError}
                onFinish={onUploadFinish}
                signingUrlHeaders={{
                  Authorization: `Bearer ${userClient.getLocalToken()}`,
                }}
                // signingUrlQueryParams={{ additional: query - params }}
                // signingUrlWithCredentials={true} // in case when need to pass authentication credentials via CORS
                // uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }} // this is the default
                contentDisposition="auto"
                scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/gi, '')}
                server={process.env.REACT_APP_API_ENDPOINT}
                autoUpload={true}
              />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="far fa-image"></i>
                </span>
                <span className="file-label">
                  Choose
                  <br />a photo
                </span>
              </span>
            </label>
          </div>
        </div>
      </div>
      {error && <p className="help is-danger">{error}</p>}
    </div>
  )
}

export { RecipeUploadPhoto as default }
