import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import Modal from 'react-modal'
import * as serviceWorker from './serviceWorker'
import './styles/styles.scss'
import './i18n'

const appElement = document.getElementById('root')
Modal.setAppElement(appElement)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  appElement,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
