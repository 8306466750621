import React from 'react'

const websites = [
  { name: 'Marmiton', url: 'https://www.marmiton.org/' },
  { name: 'Cuisine AZ', url: 'https://www.cuisineaz.com/categories/' },
  { name: '750g', url: 'https://www.750g.com/home_rubrique_-_recettes.htm' },
  { name: 'Cuisine actuelle', url: 'https://www.cuisineactuelle.fr/recettes-de-cuisine' },
  {
    name: 'Le journal des femmes',
    url: 'https://cuisine.journaldesfemmes.fr/toutes-les-recettes/',
  },
  { name: 'Madame Le Figaro', url: 'https://madame.lefigaro.fr/recettes/recherche' },
  { name: 'Marie Claire', url: 'https://www.marieclaire.fr/cuisine/recettes/' },
  { name: 'Elle Cuisine', url: 'https://www.elle.fr/Elle-a-Table/Recettes-de-cuisine' },
  { name: 'Papilles et Pupilles', url: 'http://www.papillesetpupilles.fr/' },
  { name: 'Herve Cuisine', url: 'https://www.hervecuisine.com/' },
  { name: 'Atelier des chefs', url: 'https://www.atelierdeschefs.fr/fr/recettes-de-cuisine.php' },
  { name: 'Supertoinette', url: 'https://www.supertoinette.com/recettes-cuisine-photos' },
  { name: 'Meilleur du chef', url: 'https://www.meilleurduchef.com/fr/recette.html' },
]

const RecipeImportSuggestions = () => {
  return (
    <ul className="recipe-importer__suggestions-links">
      {websites.map((website, i) => {
        return (
          <li key={i}>
            <a href={website.url} target="blank">
              {website.name}
            </a>
          </li>
        )
      })}
    </ul>
  )
}

export { RecipeImportSuggestions as default }
