import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../context/auth-context'
import pathToTitle from '../utils/pathToTitle'

const PublicRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuth()
  const isAuthenticated = Object.keys(user).length > 0

  useEffect(() => {
    pathToTitle(rest.path)
  }, [rest.path])

  return (
    <Route
      {...rest}
      component={(props) => (isAuthenticated ? <Redirect to="/" /> : <Component {...props} />)}
    />
  )
}

export { PublicRoute as default }
