import React, { useState, useCallback, useEffect } from 'react'
import i18n from '../i18n'
import * as userClient from '../utils/api/user-client'
import { initApi } from '../utils/api/api-client'
import FullpageLoader from '../components/FullPageLoader'

const AuthContext = React.createContext()

function AuthProvider(props) {
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true)

  const setUiLanguage = useCallback(
    (userData) => {
      const currentLang = user.lang

      if (userData.lang && userData.lang !== currentLang) {
        // set UI language
        i18n.changeLanguage(userData.lang)
      } else if (userData.lang === '' || !userData.lang) {
        // get lang value from local storage
        userData.lang = i18n.language.slice(0, 2)
      }
      return userData
    },
    [user.lang],
  )

  const updateUser = useCallback(async () => {
    let data = await userClient.get()
    if (data) {
      data = setUiLanguage(data)
      setUser(data)
    }
    setLoading(false)
  }, [setUiLanguage])

  const login = (token) => {
    // set local token
    userClient.setLocalToken(token)
    // update token in API instance - for future API calls
    initApi()
    // update user in app context
    updateUser()
  }

  const logout = () => {
    userClient.deleteLocalToken()
    setUser({})
  }

  useEffect(() => {
    // init user data
    // don't user updateUser function or it will re-render the app
    async function fetchData() {
      const data = await userClient.get()
      if (data) {
        setUiLanguage(data)
        setUser(data)
      }
      setLoading(false)
    }
    fetchData()
  }, [setUiLanguage])

  if (loading) {
    return <FullpageLoader />
  }
  return <AuthContext.Provider value={{ user, login, logout, updateUser }} {...props} />
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthProvider as default, useAuth }
