import React, { useState, useRef } from 'react'
import Modal from 'react-modal'
import { useTranslation, Trans } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'
import { useAuth } from '../../context/auth-context'
import * as userClient from '../../utils/api/user-client'

const SettingsPasswordModal = ({ modalIsOpen, closeModal }) => {
  const { t } = useTranslation()
  const { register, handleSubmit, errors, watch } = useForm()
  const { addToast } = useToasts()
  const { user, updateUser } = useAuth()

  const [loading, setLoading] = useState(false)

  const newPassword = useRef({})
  newPassword.current = watch('newPassword', '')

  const onSubmit = async (data) => {
    setLoading(true)
    await editPassword(data.newPassword)
  }

  const checkPassword = async (password) => {
    const result = await userClient.login({ email: user.email, password })
    return Boolean(result)
  }

  const editPassword = async (password) => {
    const result = await userClient.update({ password })

    if (result) {
      await updateUser()
    } else {
      closeModal()
      addToast(
        <Trans i18nKey="error.network_unspecific">
          <strong>Sorry but something went wrong 😦</strong>
          <div>Maybe try a little bit later</div>
        </Trans>,
        { appearance: 'error' },
      )
    }
  }

  return (
    <Modal
      isOpen={!!modalIsOpen}
      onRequestClose={closeModal}
      closeTimeoutMS={200}
      contentLabel="Selected Option"
      className="modal is-active">
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{t('Edit your password')}</p>
          <button className="delete" aria-label="close" onClick={closeModal}></button>
        </header>
        <section className="modal-card-body">
          <form onSubmit={handleSubmit(onSubmit)} id="formUserPasswordEdit">
            <div className="field">
              <label className="label">{t('New Password')}</label>
              <div className="control">
                <input
                  className="input"
                  type="password"
                  name="newPassword"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="none"
                  autoFocus
                  ref={register({
                    required: t('You must specify a password'),
                    minLength: {
                      value: 8,
                      message: t('Password must have at least 8 characters'),
                    },
                  })}
                />
              </div>
              {errors.newPassword && <p className="help is-danger">{errors.newPassword.message}</p>}
            </div>

            <div className="field">
              <label className="label">{t('Confirm Password')}</label>
              <div className="control">
                <input
                  className="input"
                  type="password"
                  name="newPasswordConfirm"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="none"
                  ref={register({
                    validate: (value) =>
                      value === newPassword.current || t('The passwords do not match'),
                  })}
                />
              </div>
              {errors.newPasswordConfirm && (
                <p className="help is-danger">{errors.newPasswordConfirm.message}</p>
              )}
            </div>

            <div className="field">
              <label className="label">{t('Current Password')}</label>
              <div className="control">
                <input
                  className="input"
                  type="password"
                  name="password"
                  autoComplete="off"
                  ref={register({
                    required: t('You must specify your current password'),
                    validate: async (value) =>
                      (await checkPassword(value)) === true ||
                      t('This is not your current password'),
                  })}
                />
              </div>
              {errors.password && <p className="help is-danger">{errors.password.message}</p>}
            </div>
          </form>
        </section>
        <footer className="modal-card-foot">
          <button className="button" onClick={closeModal}>
            {t('Cancel')}
          </button>
          <button
            className={`button is-danger ${loading ? 'is-loading' : ''}`}
            form="formUserPasswordEdit">
            {t('Save')}
          </button>
        </footer>
      </div>
    </Modal>
  )
}

export default SettingsPasswordModal
