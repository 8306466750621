import React, { useEffect, useState, useRef } from 'react'
import { useLocation, Redirect } from 'react-router-dom'
import _ from 'lodash'

import { useAuth } from '../../context/auth-context'
import { getRecipes } from '../../utils/api/recipe-client'
import RecipeListToolbar from './RecipeListToolbar'
import RecipeListGallery from './RecipeListGallery'
import RecipeListTable from './RecipeListTable'
import RecipeListEmpty from './RecipeListEmpty'
import FullPageLoader from '../FullPageLoader'
import { restoreScrollPosition } from '../../utils/helpers'

const RecipeList = () => {
  const location = useLocation()
  const { user } = useAuth()

  const [recipes, setRecipes] = useState([])
  const [loading, setLoading] = useState(true)
  const [viewType, setViewType] = useState('gallery')

  const isMounted = useRef(null)

  const searchRecipes = async (searchStr) => {
    setLoading(true)
    const results = await getRecipes(searchStr)
    if (isMounted.current) {
      setRecipes(results)
      setLoading(false)
      restoreScrollPosition()
    }
  }

  const searchRecipesDebounced = _.debounce(searchRecipes, 500)

  useEffect(() => {
    isMounted.current = true
    searchRecipes()
    return () => (isMounted.current = false)
  }, [])

  const getContent = () => {
    if (user.recipeCnt === 0 && location.pathname.indexOf('welcome-step') === -1) {
      // goToOnboarding
      return <Redirect to="/welcome-step-1" />
    } else if (loading) {
      return <FullPageLoader />
    } else {
      if (recipes.length > 0) {
        return viewType === 'gallery' ? (
          <RecipeListGallery recipes={recipes} />
        ) : (
          <RecipeListTable recipes={recipes} />
        )
      }
      return <RecipeListEmpty />
    }
  }

  return (
    <>
      <RecipeListToolbar
        viewType={viewType}
        setViewType={setViewType}
        searchRecipes={searchRecipesDebounced}
        recipes={recipes}
        loading={loading}
      />

      {getContent()}
    </>
  )
}

export { RecipeList as default }
