import React from 'react'
import AppProvider from '../context/index'
import AppRouter from '../routers/AppRouter'

const App = () => {
  return (
    <AppProvider>
      <AppRouter />
    </AppProvider>
  )
}

export default App
