import axios from 'axios'
import * as userClient from './user-client'

let api
const initApi = () => {
  let config = {
    baseURL: process.env.REACT_APP_API_ENDPOINT,
  }
  const token = userClient.getLocalToken()
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  api = axios.create(config)
}

initApi()

export { api as default, initApi }
