import React from 'react'
import RecipeImportForm from '../RecipeImport/RecipeImportForm'
import RecipeImportSuggestions from '../RecipeImport/RecipeImportSuggestions'
import { useTranslation, Trans } from 'react-i18next'

const OnboardingImport = () => {
  const { t } = useTranslation()

  return (
    <div className="onboarding m-t-l">
      <div className="onboarding__wrap">
        <div className="onboarding__logo app-logo m-b-xl">Bergamot</div>
        <h1 className="title">
          <Trans i18nKey="Import your first recipe">
            Import your <span className="has-text-yellow">first recipe</span>
          </Trans>
        </h1>
        <p className="m-b-l">
          {t('Go get a recipe URL, on any website you like, and copy/paste it below')}
        </p>
        <RecipeImportForm />
      </div>
      <div className="onboarding__separator"></div>
      <div className="onboarding__suggestions">
        <div className="onboarding__wrap">
          <div className="title is-4">{t('Recipe websites suggestions')}</div>
          <RecipeImportSuggestions />
        </div>
      </div>
    </div>
  )
}

export { OnboardingImport as default }
