import React from 'react'
import path from 'path'
import Modal from 'react-modal'
import { useTranslation, Trans } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useRef } from 'react'

const RecipeShareModal = ({ modalIsOpen, closeModal, recipe }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const publicLinkRef = useRef()

  if (!recipe.hasOwnProperty('shortId')) {
    recipe.shortId = ''
  }
  const publicLink = process.env.REACT_APP_DOMAIN + path.join('/shared', recipe.shortId)

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(publicLink)
    addToast(
      <Trans i18nKey="public_link_to_cp">
        <strong>Link is now in your clipboard</strong>
        <div>Send it to a gourmet friend 🚀</div>
      </Trans>,
      { appearance: 'success' },
    )
  }

  const selectText = () => {
    publicLinkRef.current.select()
    console.log('selectText')
  }

  return (
    <Modal
      isOpen={!!modalIsOpen}
      onRequestClose={closeModal}
      closeTimeoutMS={200}
      contentLabel="Selected Option"
      className="modal is-active recipe-link__modal">
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{t('Share your recipe')}</p>
          <button className="delete" aria-label="close" onClick={closeModal}></button>
        </header>
        <section className="modal-card-body">
          <p className="m-b-m">
            {t(
              'People with this link can view this recipe and add it to their personal collection',
            )}
            .
          </p>
          <div className="field has-addons">
            <div className="control is-expanded" onClick={selectText}>
              <input
                className="input"
                type="text"
                readOnly
                value={publicLink}
                ref={publicLinkRef}
              />
            </div>
            <div className="control">
              <button id="copy" className="button is-primary" onClick={copyLinkToClipboard}>
                {t('Copy the link')}
              </button>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button" onClick={closeModal}>
            {t('Close')}
          </button>
        </footer>
      </div>
    </Modal>
  )
}

export default RecipeShareModal
