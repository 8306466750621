import _ from 'lodash'
import api from './api-client'

async function getRecipes(q) {
  try {
    const response = await api.get('/recipes', { params: { q } })
    if (response.status !== 200) {
      return null
    }
    if (!_.isEmpty({ response }) && response.data.length > 0) {
      return response.data
    } else {
      return []
    }
  } catch (e) {
    return false
  }
}

async function getRecipe(id) {
  id = parseInt(id)
  if (isNaN(id)) {
    console.log(`Error: get recipe has to be used with a proper id : ${id}`)
    return null
  }
  try {
    const response = await api.get(`/recipes/${id}`)
    if (response.status !== 200) {
      return null
    }
    return response.data
  } catch (e) {
    return null
  }
}

async function createRecipe(data) {
  try {
    const response = await api.post(`/recipes/`, data)
    if (response.status === 201 && response.data) {
      return response.data.id
    }
    return false
  } catch (e) {
    return false
  }
}

async function updateRecipe(id, data) {
  try {
    const response = await api.put(`/recipes/${id}`, data)
    if (response.status === 200) {
      return true
    }
    return false
  } catch (e) {
    return false
  }
}

/**
 *
 * @param {int} id : id of the recipe
 * @param {array} photos : names of the files in amazon S3
 */
async function saveRecipePhotos(id, photos) {
  try {
    const response = await api.post(`/recipes/${id}/photos`, { photos })
    if (response.status === 201) {
      return true
    }
    return false
  } catch (e) {
    return false
  }
}

async function deleteRecipe(id) {
  id = parseInt(id)
  if (isNaN(id)) {
    throw new Error(`get recipe has to be used with a proper id : ${id}`)
  }
  try {
    const response = await api.delete(`/recipes/${id}`)
    if (response.status === 204) {
      return true
    }
    return false
  } catch (e) {
    return false
  }
}

// FAVORITES

async function addFavorite(id) {
  try {
    const response = await api.put(`/recipes/${id}/favorite`)
    if (response.status === 200) {
      return true
    }
    return false
  } catch (e) {
    return false
  }
}

async function deleteFavorite(id) {
  try {
    const response = await api.delete(`/recipes/${id}/favorite`)
    if (response.status === 200) {
      return true
    }
    return false
  } catch (e) {
    return false
  }
}

// URL IMPORT

async function importRecipe(url) {
  try {
    const response = await api.post(`/recipes/import/`, { url })
    if (response.status === 201 && response.data) {
      return response.data.id
    }
    return false
  } catch (e) {
    return false
  }
}

// SHARED RECIPES

async function getSharedRecipe(shortid) {
  try {
    const response = await api.get(`/recipes/shared?r=${shortid}`)
    if (response.status !== 200) {
      return null
    }
    return response.data
  } catch (e) {
    return null
  }
}

// add shared recipe if there is one in local storage
async function addSharedRecipe() {
  const addRecipe = async (id) => {
    try {
      const response = await api.post(`/recipes/shared/${id}`)
      if (response.status !== 201) {
        return null
      }
      return response.data
    } catch (e) {
      return null
    }
  }

  const recipeId = window.localStorage.getItem('addRecipeShared')
  if (recipeId) {
    const recipe = await addRecipe(recipeId)
    if (recipe) {
      window.localStorage.removeItem('addRecipeShared')
      return recipe.id
    }
  }
  return false
}

export {
  getRecipes,
  getRecipe,
  createRecipe,
  updateRecipe,
  saveRecipePhotos,
  deleteRecipe,
  importRecipe,
  addFavorite,
  deleteFavorite,
  getSharedRecipe,
  addSharedRecipe,
}
