import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ErrorPage404 = () => {
  const { t } = useTranslation()

  return (
    <div className="page404">
      <div>
        <img src="/images/404-japan-food-green-light.png" alt="no recipe" />
        <h1>{t('Oh no ! Page not found')}</h1>
        <Link className="button is-rounded is-primary is-outlined" to={'/'}>
          {t('Go to my recipe list')}
        </Link>
      </div>
    </div>
  )
}

export { ErrorPage404 as default }
