import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import RecipeDeleteModal from './RecipeDeleteModal'
import RecipeShareModal from './RecipeShareModal'
import { deleteRecipe, addFavorite, deleteFavorite } from '../../utils/api/recipe-client'
import { useTranslation, Trans } from 'react-i18next'
import { useAuth } from '../../context/auth-context'

const RecipeToolbar = ({ id, recipe }) => {
  let history = useHistory()
  const { t } = useTranslation()
  const { updateUser } = useAuth()
  const { addToast } = useToasts()

  const [shareModalIsOpen, setShareModalIsOpen] = useState(false)
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [isFavorite, setIsFavorite] = useState(Boolean(recipe.userFavorite))

  const toggleFavorite = () => {
    if (isFavorite) deleteFavorite(id)
    else addFavorite(id)
    setIsFavorite(!isFavorite)
  }

  const handleDeleteRecipe = async () => {
    const result = await deleteRecipe(id)
    await updateUser()

    if (result) {
      // redirect to list
      history.push('/')
    } else {
      setDeleteModalIsOpen(false)
      addToast(
        <Trans i18nKey="error.network_unspecific">
          <strong>Sorry but something went wrong 😦</strong>
          <div>Maybe try a little bit later</div>
        </Trans>,
        { appearance: 'error' },
      )
    }
  }

  useEffect(() => {
    setIsFavorite(Boolean(recipe.userFavorite))
  }, [recipe.userFavorite])

  return (
    <nav className="toolbar recipe-toolbar">
      <div className="toolbar-start">
        <div className="toolbar-item">
          <button
            className="button button--back is-icon is-back is-primary is-light is-rounded"
            onClick={() => history.push('/')}>
            <span className="icon">
              <i className="fas fa-chevron-left icon-size-m"></i>
            </span>
          </button>
        </div>
      </div>
      <div className="toolbar-end">
        <div className="toolbar-item">
          <div className="buttons">
            <button
              className="button button--toolbar is-icon is-primary is-light is-rounded"
              onClick={toggleFavorite}>
              {isFavorite ? (
                <span className="icon" key="is-favorite">
                  <i className="fas fa-heart icon-size-m"></i>
                </span>
              ) : (
                <span className="icon" key="is-not-favorite">
                  <i className="far fa-heart icon-size-m"></i>
                </span>
              )}
            </button>

            <button
              className="button button--toolbar is-icon is-rounded is-primary is-light"
              onClick={(e) => {
                e.preventDefault()
                setShareModalIsOpen(true)
              }}>
              <span className="icon">
                <i className="fas fa-share icon-size-m"></i>
              </span>
            </button>

            <button
              className="button button--toolbar is-icon is-rounded is-primary is-light"
              onClick={(e) => {
                e.preventDefault()
                setDeleteModalIsOpen(true)
              }}>
              <span className="icon">
                <i className="fas fa-trash icon-size-m"></i>
              </span>
            </button>

            <Link
              className="button is-rounded is-primary"
              to={{ pathname: `/recipes/edit/${id}`, state: { recipe } }}>
              {t('Edit')}
            </Link>
          </div>
        </div>
      </div>
      <RecipeShareModal
        modalIsOpen={shareModalIsOpen}
        closeModal={() => setShareModalIsOpen(false)}
        recipe={recipe}
      />
      <RecipeDeleteModal
        modalIsOpen={deleteModalIsOpen}
        closeModal={() => setDeleteModalIsOpen(false)}
        deleteRecipe={handleDeleteRecipe}
      />
    </nav>
  )
}

export { RecipeToolbar as default }

// dropdown with for delete recipe and share
/*
<div className="dropdown is-hoverable">
  <div className="dropdown-trigger">
    <button
      className="button is-rounded"
      aria-haspopup="true"
      aria-controls="dropdown-menu3">
      <span>{t('More')}</span>
      <span className="icon">
        <i className="fas fa-angle-down" aria-hidden="true"></i>
      </span>
    </button>
  </div>
  <div className="dropdown-menu" id="dropdown-menu3" role="menu">
    <div className="dropdown-content">
      <a href="https://www.google.com" className="dropdown-item">
        {t('Share')}
      </a>
      <a
        href="#"
        className="dropdown-item has-text-danger is-lik"
        onClick={openDeleteModal}>
        {t('Delete')}
      </a>
    </div>
  </div>
</div>
*/
