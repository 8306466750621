import React from 'react'
import { useTranslation } from 'react-i18next'

const RecipeListEmpty = () => {
  const { t } = useTranslation()
  return (
    <section className="recipe-list__no-results">
      <div>
        <img src="/images/chick.svg" alt="no recipe" />
        <h2>{t('No results for this search')}</h2>
        <p>{t('Try to modify your search to see recipes showing up')}</p>
      </div>
    </section>
  )
}

export { RecipeListEmpty as default }
