import i18next from 'i18next'

const pathToTitleTable = {
  '/': 'My recipes',
  '/recipes/edit/': 'Create a new recipe',
  '/recipes/edit/:id': 'Edit your recipe',
  '/recipes/:id': '$recipe',
  '/import': 'Import a new recipe',
  '/settings': 'Settings',
  '/shared/:shortid': '$recipe',
  '/login': 'Login',
  '/register': 'Create your account',
  '/welcome-step-1': 'Discover Bergamot',
  '/welcome-step-2': 'Import your first recipe',
}

const pathToTitle = (path) => {
  if (Object.keys(pathToTitleTable).indexOf(path) !== -1) {
    document.title = 'Bergamot • ' + i18next.t(pathToTitleTable[path])
  }
}
export { pathToTitle as default }
