import React from 'react'
import RecipeListTableItem from './RecipeListTableItem'

const RecipeListGallery = ({ recipes }) => {
  return (
    <section className="recipe-table has-toolbar">
      <table>
        <tbody>
          {recipes.map((recipe) => (
            <RecipeListTableItem key={recipe.id} recipe={recipe} />
          ))}
        </tbody>
      </table>
    </section>
  )
}

export { RecipeListGallery as default }
