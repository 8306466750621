import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import SigninForm from './SigninForm'
import PasswordResetForm from './PasswordResetForm'

const SigninPage = () => {
  const { t } = useTranslation()
  const [resetPassword, setResetPassword] = useState(false)

  const togglePasswordRecovery = (e) => {
    e.preventDefault()
    setResetPassword(!resetPassword)
  }

  return (
    <div className="signin">
      <div className="columns is-gapless">
        <div className="signin__illustration column"></div>
        <div className="signin__content column">
          <div className="content">
            <div className="signin__logo">
              <img src="/images/bergamot-logo.svg" alt="Bergamot App logo" />
            </div>

            {resetPassword ? (
              <>
                <h1 className="">{t('Password Recovery')}</h1>
                <PasswordResetForm />
                <div className="signin__register-link center">
                  <a onClick={(e) => togglePasswordRecovery} href="#">
                    {t('Back')}
                  </a>
                </div>
              </>
            ) : (
              <>
                <h1 className="">{t('Log In 1')}</h1>
                <SigninForm showPasswordRecoveryForm={togglePasswordRecovery} />
                <div className="center is-flex-direction-column is-align-items-center">
                  <div className="forgot">
                    <a onClick={togglePasswordRecovery} href="#">
                      {t('Forgot password?')}
                    </a>
                  </div>
                  <div className="signin__register-link-wrapper">
                    <Trans i18nKey="need_an_account">
                      <span className="signin__register-link">Need a Bergamot account ?&nbsp;</span>
                      <Link className="signin__register-link" to={'/register'}>
                        Create an account
                      </Link>
                    </Trans>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export { SigninPage as default }
