import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Recipe404 = () => {
  const { t } = useTranslation()
  return (
    <div className="recipe__no-recipe">
      <div>
        <img src="/images/404-japan-food-green-light.png" alt="no recipe" />
        <h2>{t("Sorry, but we didn't find any recipe at this address")}</h2>
        <Link className="button is-rounded is-primary is-light" to={'/'}>
          {t('Go to my recipe list')}
        </Link>
      </div>
    </div>
  )
}

export { Recipe404 as default }
