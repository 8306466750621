import React, { useMemo } from 'react'
import { changeImageSize } from '../../utils/helpers'
import Image from '../Image'

const RecipePhotos = ({ recipe }) => {
  const photoSrc = useMemo(() => {
    if (recipe.photos && recipe.photos[0]) {
      return changeImageSize(recipe.photos[0].photoThumbUrl, 600, 450)
    } else {
      return `/images/placeholder-1200x800-${recipe.id % 4}.png`
    }
  }, [recipe.id, recipe.photos])

  return (
    <div className="recipe__image">
      <div className="recipe__image-wrapper">
        <Image
          src={photoSrc}
          width="600"
          height="450"
          alt={recipe.title}
          className="recipe__image-img"
        />
      </div>
    </div>
  )
}

export { RecipePhotos as default }
