import React, { useEffect, useState, useRef } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Trans } from 'react-i18next'
import RecipeToolbar from './RecipeToolbar'
import RecipePhotos from './RecipePhotos'
import RecipeIntro from './RecipeIntro'
import RecipeIngredients from './RecipeIngredients'
import RecipeInstructions from './RecipeInstructions'
import FullPageLoader from '../FullPageLoader'
import Recipe404 from '../Recipe/Recipe404'
import { getRecipe } from '../../utils/api/recipe-client'

const Recipe = (props) => {
  let { id } = useParams()
  let location = useLocation()
  const { addToast } = useToasts()

  const [recipe, setRecipe] = useState({})
  const [noRecipe, setNoRecipe] = useState(false)
  const [loading, setLoading] = useState(true)
  const [partialLoading, setPartialLoading] = useState(true)
  const isMounted = useRef(null)

  useEffect(() => {
    // page title
    if (recipe && recipe.title) {
      document.title = document.title.replace('$recipe', recipe.title)
    }
  })

  useEffect(() => {
    // set scroll postion to top
    window.scrollTo(0, 0)

    if (location.state && location.state.recipe && Object.keys(location.state.recipe).length) {
      // user comes from a link on the recipe list
      setRecipe(location.state.recipe)
      setPartialLoading(true)
    } else {
      // user opens from a url
      setLoading(true)
    }

    const fetchData = async () => {
      //setLoading(true)
      isMounted.current = true
      const data = await getRecipe(id)
      if (isMounted.current) {
        if (!data) {
          setNoRecipe(true)
        }
        setRecipe(data)
        setPartialLoading(false)
        setLoading(false)
      }
    }
    fetchData()

    // Show a toast if the recipe has just been added from a recipe sharing
    if (location.state && location.state.recipeSharedAdded === true) {
      location.state = {}
      addToast(
        <Trans i18nKey="recipe shared just added">
          <strong>The recipe has been added 🐸</strong>
          <div>It's all yours !</div>
        </Trans>,
        { appearance: 'success' },
      )
    }

    return () => (isMounted.current = false)
  }, [id, addToast, location.state])

  // Image placeholder at loading
  // https://itnext.io/stable-image-component-with-placeholder-in-react-7c837b1ebee

  return (
    <>
      {!noRecipe ? (
        <>
          {!loading ? (
            <>
              <RecipeToolbar id={id} recipe={recipe} />
              <div className="recipe has-toolbar">
                <section className="recipe__header columns is-variable is-4">
                  <div className="column recipe__column--photo">
                    <RecipePhotos id={id} recipe={recipe} />
                  </div>
                  <div className="column">
                    <RecipeIntro recipe={recipe} />
                  </div>
                </section>

                <section className="recipe__content columns is-variable is-4">
                  <div className="column recipe__column--ingredients">
                    <RecipeIngredients recipe={recipe} loading={partialLoading} />
                  </div>
                  <div className="column">
                    <RecipeInstructions recipe={recipe} loading={partialLoading} />
                  </div>
                </section>
              </div>
            </>
          ) : (
            <FullPageLoader />
          )}
        </>
      ) : (
        <Recipe404 />
      )}
    </>
  )
}

export { Recipe as default }
