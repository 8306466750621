import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'

const OnboardingIntro = () => {
  const { t } = useTranslation()
  return (
    <div className="onboarding m-t-l">
      <div className="onboarding__wrap columns is-variable is-7">
        <div className="column">
          <div className="onboarding__logo app-logo m-b-xl">Bergamot</div>
          <Trans i18nKey="Keep your recipes forever">
            <h1 className="title">
              Keep your
              <br />
              <span className="has-text-yellow">recipes forever</span>
            </h1>
          </Trans>
          <p className="m-b-l">
            <Trans i18nKey="Onboarding intro">
              From any website, import the recipes you love in your collection. <br />
              Get them from anywhere, anytime.
            </Trans>
          </p>
          <p>
            <Link to="/welcome-step-2" className="button is-primary is-medium">
              <span>{t('Start')}</span>
              <span className="icon">
                <i className="fas fa-angle-right"></i>
              </span>
            </Link>
          </p>
        </div>
        <div className="onboarding__img-wrapper column is-hidden-mobile">
          <img
            className="onboarding__img1"
            src="images/screenshot-recipelist-fr.png"
            alt="Bergamot app with many recipes"
          />
        </div>
      </div>
    </div>
  )
}

export { OnboardingIntro as default }
