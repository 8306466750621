import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import SignupForm from './SignupForm'

const SignupPage = () => {
  const { t } = useTranslation()

  return (
    <div className="signin">
      <div className="columns is-gapless">
        <div className="signin__illustration signin__illustration--register column"></div>
        <div className="signin__content column">
          <div className="content">
            <h1 className="title">{t('Welcome to Bergamot')}</h1>
            <div className="signin__intro">
              <Trans i18nKey="register_intro">
                Save your favorites recipes forever.
                <br />
                Find them anywhere, anytime.
                <br />
                The ultimate tool to be the perfect cook you are.
                <br />
              </Trans>
            </div>
            <SignupForm />
            <div className="signin__register-link-wrapper">
              <div>
                <Trans i18nKey="have_an_account">
                  <span className="signin__register-link">Already have an account ?&nbsp;</span>
                  <Link className="signin__register-link" to={'/login'}>
                    Log in
                  </Link>
                </Trans>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { SignupPage as default }
