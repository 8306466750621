import React from 'react'
import BubbleLoader from './BubbleLoader'

const FullPageLoader = () => {
  return (
    <div className="fullpageloader">
      <BubbleLoader />
    </div>
  )
}

export { FullPageLoader as default }
