import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../context/auth-context'
import * as userClient from '../../utils/api/user-client'
import SettingsEmailModal from './SettingsEmailModal'
import SettingsPasswordModal from './SettingsPasswordModal'

const Settings = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { user, updateUser, logout } = useAuth()

  const [emailModalIsOpen, setEmailModalIsOpen] = useState(false)
  const [passwordModalIsOpen, setPasswordModalIsOpen] = useState(false)

  const changeLanguage = async (e) => {
    await userClient.update({ lang: e.target.value })
    updateUser()
  }

  const handleLogout = () => {
    logout()
    history.push(`/login`)
  }

  return (
    <div className="default-layout settings">
      <div className="default-layout__wrap">
        <section className="section">
          <h1 className="title">{t('Settings')}</h1>
          <div className="box box--flat">
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">{t('Language')}</label>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select onChange={changeLanguage} defaultValue={user.lang}>
                        <option value="en">{t('lang.en')}</option>
                        <option value="fr">{t('lang.fr')}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">{t('Email')}</label>
              </div>
              <div className="field-body">
                <div className="field is-grouped">
                  <p className="control has-icons-left is-expanded input-w-s">
                    <input
                      className="input"
                      type="text"
                      placeholder="{t('Email')}"
                      value={user.email}
                      disabled
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-user"></i>
                    </span>
                  </p>
                  <p className="control">
                    <button
                      className="button is-primary is-light"
                      onClick={() => setEmailModalIsOpen(true)}>
                      <span className="icon">
                        <i className="fas fa-pen"></i>
                      </span>
                    </button>
                  </p>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">{t('Password')}</label>
              </div>
              <div className="field-body">
                <div className="field is-grouped">
                  <p className="control has-icons-left is-expanded input-w-s">
                    <input
                      className="input"
                      type="password"
                      placeholder="{t('Password')}"
                      value="*****"
                      disabled
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-lock"></i>
                    </span>
                  </p>
                  <p className="control">
                    <button
                      className="button is-primary is-light"
                      onClick={() => setPasswordModalIsOpen(true)}>
                      <span className="icon">
                        <i className="fas fa-pen"></i>
                      </span>
                    </button>
                  </p>
                </div>
              </div>
            </div>

            <hr />

            <div className="field  is-horizontal">
              <div className="field-label is-normal"></div>
              <div className="field-body">
                <button className="button is-dark is-light" onClick={handleLogout}>
                  <span className="icon">
                    <i className="fas fa-sign-out-alt"></i>
                  </span>
                  <span>{t('Log Out')}</span>
                </button>
              </div>
            </div>
            <div className="version">
              {process.env.REACT_APP_NAME.charAt(0).toUpperCase() +
                process.env.REACT_APP_NAME.slice(1)}{' '}
              {process.env.REACT_APP_VERSION}
            </div>
          </div>
        </section>
      </div>
      <SettingsEmailModal
        modalIsOpen={emailModalIsOpen}
        closeModal={() => setEmailModalIsOpen(false)}
      />
      <SettingsPasswordModal
        modalIsOpen={passwordModalIsOpen}
        closeModal={() => setPasswordModalIsOpen(false)}
      />
    </div>
  )
}

export { Settings as default }
