import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatIngredients, strongQuantitiesRegex } from '../../utils/helpers'
import ContentLoader from 'react-content-loader'
import { useEffect } from 'react'

const RecipeIngredients = ({ recipe, loading }) => {
  const { t } = useTranslation()
  const [servingsInit, setServingsInit] = useState(1)
  const [servings, setServings] = useState(1)

  const ingredients = recipe.ingredients

  const adaptIngredient = (ingredient, servings, newServings) => {
    const ratio = newServings / servings
    return strongQuantitiesRegex(ingredient, (a, quantity) => {
      // keep only 3 significant numbers
      const round = (number) => {
        const places = 3
        number = Math.round(number * 100) / 100
        if (!number) return number
        number = number * 1000
        const p = Math.round(Math.log10(parseInt(number)))
        const f = Math.pow(10, p - places + 1)
        number = Math.round(parseInt(number) / f) * f
        number /= 1000
        return number
      }
      return a.replace(quantity, round(quantity * ratio))
    })
  }

  const smallServings = [0.25, 0.5, 0.75, 1]
  const addServing = () => {
    if (servings >= 1) {
      setServings(servings + 1)
    } else if (smallServings.indexOf(servings) !== -1) {
      const index = smallServings.indexOf(servings)
      setServings(smallServings[index + 1])
    }
  }

  const subServing = () => {
    if (servings > 1) {
      setServings(servings - 1)
    } else if (smallServings.indexOf(servings) !== -1) {
      const index = smallServings.indexOf(servings)
      if (index > 0) {
        setServings(smallServings[index - 1])
      }
    }
  }

  useEffect(() => {
    // initialize servings
    const servings = recipe.servings ? recipe.servings : 1
    setServingsInit(servings)
    setServings(servings)
  }, [recipe.servings])

  return (
    <div className="recipe__ingredients recipe--mobile-padding">
      {!loading ? (
        <>
          <div className="recipe__ingredients-header">
            <h2 className="recipe__subtitle title is-6">{t('Ingredients')}</h2>
            <div className="recipe__servings">
              <span className="recipe__servings-label">
                {t('#n servings', { count: servings })}
              </span>
              {ingredients && ingredients.length > 0 && (
                <div className="recipe__servings-buttons buttons has-addons">
                  <button
                    className="button is-small"
                    disabled={servings === 0.25 ? 'disabled' : ''}
                    onClick={subServing}>
                    <span className="icon is-small">
                      <i className="fas fa-minus"></i>
                    </span>
                  </button>
                  <button className="button is-small" onClick={addServing}>
                    <span className="icon is-small">
                      <i className="fas fa-plus"></i>
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>

          {ingredients && ingredients.length > 0 ? (
            <div className="recipe__ingredients-blocks">
              {ingredients.map((sublist, j) => {
                return (
                  <div key={`ingredients_${j}`}>
                    <div className="recipe__ingredients-subtitle">{sublist.title}</div>
                    <ul className="recipe__ingredients-list">
                      {!!sublist.data.length &&
                        sublist.data.map((ingredient, i) => {
                          if (ingredient) {
                            return (
                              <li key={i} className="recipe__ingredient">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: formatIngredients(
                                      adaptIngredient(ingredient, servingsInit, servings),
                                    ),
                                  }}
                                />
                              </li>
                            )
                          } else {
                            return null
                          }
                        })}
                    </ul>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className="recipe__ingredients__no-ingredients">
              <div className="recipe__ingredients__no-ingredients-emoji">{'ʕ⊙ᴥ⊙ʔ'}</div>
              <div className="recipe__ingredients__no-ingredients-text">{t('No ingredients')}</div>
            </div>
          )}
        </>
      ) : (
        <ContentLoader
          speed={2}
          width={220}
          height={160}
          viewBox="0 0 220 160"
          backgroundColor="#e3e8eb"
          foregroundColor="#f1f3f5">
          <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
          <rect x="0" y="50" rx="3" ry="3" width="220" height="10" />
          <rect x="0" y="80" rx="3" ry="3" width="220" height="10" />
          <rect x="0" y="110" rx="3" ry="3" width="220" height="10" />
          <rect x="0" y="140" rx="3" ry="3" width="220" height="10" />
        </ContentLoader>
      )}
    </div>
  )
}

export { RecipeIngredients as default }
