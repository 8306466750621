import React from 'react'

// https://medium.com/@josephwong2004/9-different-css-only-loading-indicator-animation-5d9f2c57a33a

const BubbleLoader = () => {
  return (
    <div className="bubble-loader">
      <div className="bubbles">
        <div className="wrapper">
          <div className="ball1"></div>
          <div className="ball2"></div>
          <div className="ball3"></div>
          <div className="ball4"></div>
        </div>
      </div>
    </div>
  )
}

export { BubbleLoader as default }
