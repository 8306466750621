import React from 'react'
import RecipeListGalleryItem from './RecipeListGalleryItem'

const RecipeListGallery = ({ recipes }) => {
  return (
    <section className="recipe-list has-toolbar">
      {recipes.map((recipe) => (
        <RecipeListGalleryItem key={recipe.id} recipe={recipe} />
      ))}
    </section>
  )
}

export { RecipeListGallery as default }
