import React, { useState } from 'react'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'

const RecipeDeleteModal = ({ modalIsOpen, closeModal, deleteRecipe }) => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const confirmDelete = () => {
    setLoading(true)
    deleteRecipe()
  }

  return (
    <Modal
      isOpen={!!modalIsOpen}
      onRequestClose={closeModal}
      closeTimeoutMS={200}
      contentLabel="Selected Option"
      className="modal is-active">
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{t('Delete recipe')}</p>
          <button className="delete" aria-label="close" onClick={closeModal}></button>
        </header>
        <section className="modal-card-body">
          <div>{t('This will delete this recipe from your collection, are you sure ?')}</div>
        </section>
        <footer className="modal-card-foot">
          <button className="button" onClick={closeModal}>
            {t('Cancel')}
          </button>
          <button
            className={`button is-danger ${loading ? 'is-loading' : ''}`}
            onClick={confirmDelete}>
            {t('Delete')}
          </button>
        </footer>
      </div>
    </Modal>
  )
}

export default RecipeDeleteModal
