import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import PasswordRecoveryForm from './PasswordRecoveryForm'
import BubbleLoader from '../BubbleLoader'
import * as useClient from '../../utils/api/user-client'

const PasswordRecoveryPage = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const [email, setEmail] = useState(false)
  const [token, setToken] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const checkToken = async (email, token) => {
      setLoading(true)
      const result = await useClient.checkResetPasswordToken(email, token)
      setEmail(email)
      setToken(token)
      if (result) {
        setLoading(false)
      } else {
        history.push('/')
      }
    }

    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams.get('email') && urlParams.get('token')) {
      checkToken(urlParams.get('email'), urlParams.get('token'))
    }
  }, [history])

  return (
    <div className="signin">
      <div className="columns is-gapless">
        <div className="signin__illustration column"></div>
        <div className="signin__content column">
          <div className="content">
            <div className="signin__logo">
              <img src="/images/bergamot-logo.svg" alt="Bergamot App logo" />
            </div>

            <h2 className="">{t('Password Recovery')}</h2>
            {loading ? (
              <div className="m-t-xl">
                <BubbleLoader />
              </div>
            ) : (
              <PasswordRecoveryForm email={email} token={token} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export { PasswordRecoveryPage as default }
