import React, { useState } from 'react'
import Modal from 'react-modal'
import { useTranslation, Trans } from 'react-i18next'
import SignupForm from '../Authentication/SignupForm'
import SigninForm from '../Authentication/SigninForm'

const RecipeSharedRegisterModal = ({ modalIsOpen, closeModal, deleteRecipe }) => {
  const { t } = useTranslation()
  const [content, setContent] = useState('register')
  const toogleContent = () => {
    if (content === 'register') {
      setContent('login')
    } else {
      setContent('register')
    }
  }
  return (
    <Modal
      isOpen={!!modalIsOpen}
      onRequestClose={closeModal}
      closeTimeoutMS={200}
      contentLabel="Selected Option"
      className="modal is-active recipe-shared__modal">
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="recipe-shared__modal__logo">
          <img src="/images/bergamot-logo.svg" alt="Bergamot App logo" />
        </div>
        <p className="text-center m-b-l">
          <Trans i18nKey="sharepage_intro">
            <strong>Sauvegardez toutes vos recettes</strong> sur Bergamot en quelques clics, et
            retrouvez-les depuis votre smartphone ou tablette.
          </Trans>
        </p>

        {content === 'register' ? (
          <>
            <SignupForm />
            <hr className="hr" />
            <p className="text-center">
              <button className="button-as-link button-styleless is-text" onClick={toogleContent}>
                {t('Log In')}
              </button>
            </p>
          </>
        ) : (
          <>
            <SigninForm />
            <hr className="hr" />
            <p className="text-center">
              <button className="button-as-link button-styleless is-text" onClick={toogleContent}>
                {t('Create a free account')}
              </button>
            </p>
          </>
        )}
      </div>
      <button className="modal-close delete" aria-label="close" onClick={closeModal}></button>
    </Modal>
  )
}

export default RecipeSharedRegisterModal
