import React from 'react'
import { useTranslation } from 'react-i18next'
import ContentLoader from 'react-content-loader'

const RecipeInstructions = ({ recipe, loading }) => {
  const { t } = useTranslation()
  const instructions = recipe.instructions
  const hasInstructions = instructions && Object.keys(instructions).length > 0
  let stepIndex = 1

  return (
    <>
      {!loading ? (
        <div className="recipe__instructions recipe--mobile-padding">
          <h2 className="recipe__subtitle title is-6 is-hidden-tablet">{t('Instructions')}</h2>
          {hasInstructions ? (
            <>
              {instructions.map((sublist, j) => {
                return (
                  <div key={`instructions_${j}`}>
                    {sublist.title && (
                      <div className="recipe__instructions-subtitle">{sublist.title}</div>
                    )}
                    <ul className="recipe__instructions-list">
                      {sublist.data.length > 0 &&
                        sublist.data.map((instruction) => {
                          return (
                            <li key={stepIndex} className="recipe__instruction columns is-mobile">
                              <div className="recipe__instruction_number column">
                                {('0' + stepIndex++).slice(-2)}
                              </div>
                              <div className="recipe__instruction_text column">{instruction}</div>
                            </li>
                          )
                        })}
                    </ul>
                  </div>
                )
              })}
            </>
          ) : (
            <div className="recipe__instructions__no-instructions">
              <div className="recipe__instructions__no-instructions-emoji">{'ʕ◉ᴥ◉ʔ'}</div>
              <div className="recipe__instructions__no-instructions-text">
                {t('No instructions')}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="recipe__instructions">
          <ContentLoader
            speed={2}
            width={550}
            height={320}
            viewBox="0 0 550 320"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb">
            <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
            <rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
            <rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
            <rect x="187" y="48" rx="3" ry="3" width="72" height="11" />
            <rect x="18" y="48" rx="3" ry="3" width="100" height="11" />
            <rect x="0" y="71" rx="3" ry="3" width="37" height="11" />
            <rect x="18" y="23" rx="3" ry="3" width="140" height="11" />
            <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
            <rect x="0" y="95" rx="3" ry="3" width="67" height="11" />
            <rect x="76" y="95" rx="3" ry="3" width="140" height="11" />
            <rect x="127" y="143" rx="3" ry="3" width="53" height="11" />
            <rect x="187" y="143" rx="3" ry="3" width="72" height="11" />
            <rect x="18" y="143" rx="3" ry="3" width="100" height="11" />
            <rect x="0" y="166" rx="3" ry="3" width="37" height="11" />
            <rect x="18" y="118" rx="3" ry="3" width="140" height="11" />
            <rect x="166" y="118" rx="3" ry="3" width="173" height="11" />{' '}
          </ContentLoader>
        </div>
      )}
    </>
  )
}

export { RecipeInstructions as default }
