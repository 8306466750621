import React, { useState, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useAuth } from '../../context/auth-context'
import { importRecipe } from '../../utils/api/recipe-client'
import { useTranslation, Trans } from 'react-i18next'
import { isMobile } from '../../utils/helpers'

// NOTES
// react hook form
// https://react-hook-form.com/get-started

const RecipeImportForm = () => {
  const { register, handleSubmit /*watch,*/ /*errors*/ } = useForm()

  const [loading, setLoading] = useState(true)
  const { addToast } = useToasts()
  const history = useHistory()
  const { t } = useTranslation()

  const { updateUser } = useAuth()

  const onSubmit = async (data) => {
    // input validation
    if (data.url === '') {
      addToast(
        <Trans i18nKey="error.no_recipe_found">
          <strong>No recipe found ¯\_(ツ)_/¯</strong>
          <div>Please enter a wonderful recipe URL before clicking !</div>
        </Trans>,
        { appearance: 'warning' },
      )
      return
    } else if (
      data.url.match(
        /^https?:\/\/(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]{4,})/,
      ) === null
    ) {
      // must start with 'https://' or 'http://'
      // must continue with 'domain.ext' or 'sub.domain.ext'
      // must continue with '/123' at least 3 chars

      addToast(
        <Trans i18nKey="error.not_a_url">
          <strong>This is not a URL</strong>
          <div>
            (<i>You know, it starts with https://...</i>😇)
          </div>
        </Trans>,
        { appearance: 'warning' },
      )
      return
    }

    // Launch import
    setLoading(true)
    let error = false
    const recipeId = await importRecipe(data.url)

    if (recipeId) {
      // update user recipe count
      updateUser()
      history.push(`/recipes/${recipeId}`)
    } else {
      error = true
    }

    // An error occured or no results
    if (error) {
      setLoading(false)
      addToast(
        <Trans i18nKey="error.something_went_wrong">
          <>
            <strong>Something went wrong 😦</strong>
            <div>Maybe this is not a recipe after all</div>
          </>
        </Trans>,
        { appearance: 'error' },
      )
    }
  }

  useEffect(() => {
    setLoading(false)
  }, [])

  return (
    <form className="field-body is-expanded" onSubmit={handleSubmit(onSubmit)}>
      <div className="field is-expanded">
        <div className="field has-addons">
          <div className="control is-expanded has-icons-right">
            <input
              className="input input--grey is-medium"
              placeholder={t('Save a recipe from its URL')}
              autoComplete="off"
              name="url"
              autoFocus={isMobile() ? false : true}
              ref={register()}
            />
          </div>
          <div className="control">
            <button className={`button is-medium is-primary ${loading ? 'is-loading' : ''}`}>
              {t('Import')}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export { RecipeImportForm as default }
