import React, { useState } from 'react'
import Modal from 'react-modal'
import { useTranslation, Trans } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'
import { useAuth } from '../../context/auth-context'
import * as userClient from '../../utils/api/user-client'

const SettingsEmailModal = ({ modalIsOpen, closeModal }) => {
  const { t } = useTranslation()
  const { register, handleSubmit, errors } = useForm()
  const { addToast } = useToasts()
  const { user, updateUser } = useAuth()

  const [loading, setLoading] = useState(false)

  const onSubmit = async (data) => {
    setLoading(true)
    await editEmail(data.email)
  }

  const checkPassword = async (password) => {
    const result = await userClient.login({ email: user.email, password })
    return Boolean(result)
  }

  const editEmail = async (email) => {
    const result = await userClient.update({ email })

    if (result) {
      await updateUser()
    } else {
      closeModal()
      addToast(
        <Trans i18nKey="error.network_unspecific">
          <strong>Sorry but something went wrong 😦</strong>
          <div>Maybe try a little bit later</div>
        </Trans>,
        { appearance: 'error' },
      )
    }
  }

  return (
    <Modal
      isOpen={!!modalIsOpen}
      onRequestClose={closeModal}
      closeTimeoutMS={200}
      contentLabel="Selected Option"
      className="modal is-active">
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{t('Edit your email address')}</p>
          <button className="delete" aria-label="close" onClick={closeModal}></button>
        </header>
        <section className="modal-card-body">
          <form onSubmit={handleSubmit(onSubmit)} id="formUserEmailEdit">
            <div className="field">
              <label className="label">{t('New email address')}</label>
              <div className="control">
                <input
                  className="input"
                  name="email"
                  type="email"
                  autoFocus
                  autoCorrect="off"
                  autoCapitalize="none"
                  ref={register({
                    required: {
                      value: true,
                      message: t('Please provide an email to login'),
                    },
                    pattern: {
                      value: /^.+@[^.].*\.[a-z]{2,}$/i,
                      message: t('This is not a valid email address'),
                    },
                  })}
                />
              </div>
              {errors.email && <p className="help is-danger">{errors.email.message}</p>}
            </div>

            <div className="field">
              <label className="label">{t('Current Password')}</label>
              <div className="control">
                <input
                  className="input"
                  type="password"
                  name="password"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="none"
                  ref={register({
                    required: t('You must specify your current password'),
                    validate: async (value) =>
                      (await checkPassword(value)) === true ||
                      t('This is not your current password'),
                  })}
                />
              </div>
              {errors.password && <p className="help is-danger">{errors.password.message}</p>}
            </div>
          </form>
        </section>
        <footer className="modal-card-foot">
          <button className="button" onClick={closeModal}>
            {t('Cancel')}
          </button>
          <button
            className={`button is-danger ${loading ? 'is-loading' : ''}`}
            form="formUserEmailEdit">
            {t('Save')}
          </button>
        </footer>
      </div>
    </Modal>
  )
}

export default SettingsEmailModal
