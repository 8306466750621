import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useAuth } from '../../context/auth-context'
import { addSharedRecipe } from '../../utils/api/recipe-client'
import RecipeSharedRegisterModal from './RecipeSharedRegisterModal'

const RecipeSharedToolbar = ({ id }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { user } = useAuth()
  const isAuthenticated = Object.keys(user).length > 0

  const [loading, setLoading] = useState(false)
  const [registerModalIsOpen, setRegisterModalIsOpen] = useState(false)

  const addRecipe = async (id) => {
    setLoading(true)
    const recipeId = await addSharedRecipe(id)
    setLoading(false)
    if (recipeId) {
      history.push({ pathname: `/recipes/${recipeId}`, state: { recipeSharedAdded: true } })
    } else {
      addToast(
        <Trans i18nKey="error.network_unspecific">
          <strong>Sorry but something went wrong 😦</strong>
          <div>Maybe try a little bit later</div>
        </Trans>,
        { appearance: 'error' },
      )
    }
  }
  const openModal = (e) => {
    window.localStorage.setItem('addRecipeShared', id)
    if (!isAuthenticated) {
      e.preventDefault()
      setRegisterModalIsOpen(true)
    } else {
      addRecipe(id)
    }
  }

  useEffect(() => {}, [])

  return (
    <nav className="toolbar recipe-toolbar toolbar--nosidebar">
      <div className="toolbar-start">
        <div className="toolbar-item">
          <div className="toolbar__logo">
            <img src="/images/bergamot-logo.svg" alt="Bergamot App logo" />
          </div>
        </div>
      </div>
      <div className="toolbar-end">
        <div className="toolbar-item">
          <button
            className={`button is-rounded is-primary is-hidden-mobile ${
              loading ? 'is-loading' : ''
            }`}
            onClick={openModal}>
            {t('Add to my recipe collection')}
          </button>
          <button
            className={`button is-rounded is-primary is-small  is-hidden-tablet ${
              loading ? 'is-loading' : ''
            }`}
            onClick={openModal}>
            <span className="is-hidden-tablet">{t('Add to my collection')}</span>
          </button>
        </div>
      </div>
      <RecipeSharedRegisterModal
        modalIsOpen={registerModalIsOpen}
        closeModal={() => {
          setRegisterModalIsOpen(false)
        }}
      />
    </nav>
  )
}

export { RecipeSharedToolbar as default }
