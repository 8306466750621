import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as userClient from '../../utils/api/user-client'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import InputPassword from '../Common/InputPassword'

const PasswordRecoveryForm = ({ email, token }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { register, handleSubmit, watch, errors } = useForm()

  const [loading, setLoading] = useState(false)
  const [resetDone, setResetDone] = useState(false)

  const newPassword = useRef({})
  newPassword.current = watch('newPassword', '')

  const onSubmit = async (formData) => {
    setLoading(true)
    const result = await userClient.resetPassword(formData.newPassword, token)
    if (result) {
      setResetDone(true)
    } else {
      //
    }
    setLoading(false)
  }

  return (
    <>
      {resetDone ? (
        <div>
          <article class="message is-success">
            <div class="message-body">{t('Your password has been reset, you can now log in')}</div>
          </article>
          <div className="center  m-b-l">
            <button
              className={'button is-link'}
              onClick={() => history.push({ pathname: `/login/`, state: { email: email } })}>
              {t('Log In')}
            </button>
          </div>
        </div>
      ) : (
        <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="field">
            <div className="control has-icons-left">
              <TextField
                disabled
                value={email ? email : ''}
                label={t('Email')}
                name="email"
                variant="outlined"
                size="small"
                fullWidth={true}
                className=""
                inputRef={register({ required: true, pattern: /^.+@[^.].*\.[a-z]{2,}$/i })}
                inputProps={{
                  autoCapitalize: 'none',
                  autoCorrect: 'off',
                }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-envelope"></i>
              </span>
            </div>
            {errors.email && (
              <p className="help is-danger">{t('Please provide an email to login')}</p>
            )}
          </div>

          <div className="field">
            <InputPassword
              label={t('New Password')}
              name="newPassword"
              variant="outlined"
              size="small"
              className=""
              inputRef={register({
                required: t('You must specify a password'),
                minLength: {
                  value: 8,
                  message: t('Password must have at least 8 characters'),
                },
              })}
              fullWidth={true}
            />
            {errors.newPassword && <p className="help is-danger">{errors.newPassword.message}</p>}
          </div>

          <div className="field">
            <InputPassword
              label={t('Confirm Password')}
              name="newPasswordConfirm"
              variant="outlined"
              size="small"
              className=""
              inputRef={register({
                validate: (value) =>
                  value === newPassword.current || t('The passwords do not match'),
              })}
              fullWidth={true}
            />
            {errors.newPasswordConfirm && (
              <p className="help is-danger">{errors.newPasswordConfirm.message}</p>
            )}
          </div>

          <div className="field margin-top-small">
            <button type="submit" className={`button is-link ${loading ? 'is-loading' : ''}`}>
              {t('Reset password')}
            </button>
          </div>
        </form>
      )}
    </>
  )
}

export { PasswordRecoveryForm as default }
