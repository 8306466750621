import { setCookie, getCookie, eraseCookie } from '../cookies'
import api from './api-client'

function setLocalToken(value) {
  setCookie('userJwt', value, 7)
}

function getLocalToken() {
  return getCookie('userJwt')
}

function deleteLocalToken() {
  eraseCookie('userJwt')
}

async function get() {
  const token = getCookie('userJwt')

  if (!token) {
    return null
  }

  try {
    const response = await api.get('user')
    if (response.status !== 200) {
      return null
    }
    return response.data
  } catch (e) {
    console.log(e)
    return null
  }
}

async function login({ email, password }) {
  const id = { email, password }

  try {
    const response = await api.post(`/user/login/`, id)
    if (
      response.status === 200 &&
      response.data.hasOwnProperty('success') &&
      response.data.success
    ) {
      return response.data.token
    } else {
      return null
    }
  } catch (e) {
    console.log(e)
    return null
  }
}

async function create({ email, password }) {
  const data = { email, password, lang: navigator.language }

  try {
    const response = await api.post(`/user/`, data)
    if (response.status === 201) {
      return true
    } else {
      return null
    }
  } catch (e) {
    if (e.response.status === 409) {
      return false // email already exists
    }
    return null
  }
}

async function update(updates) {
  try {
    const response = await api.put(`/user/`, updates)
    if (response.status === 200) {
      return true
    } else {
      return null
    }
  } catch (e) {
    return null
  }
}

async function resetPasswordRequest(email, lang) {
  try {
    const response = await api.post(`/user/password-reset/request`, { email, lang })
    if (response.status === 200) {
      return true
    } else {
      return null
    }
  } catch (e) {
    return null
  }
}

async function checkResetPasswordToken(email, token) {
  try {
    const response = await api.post(`/user/password-reset/check`, { email, token })
    if (response.status === 200) {
      return true
    } else {
      return null
    }
  } catch (e) {
    return null
  }
}

async function resetPassword(password, token) {
  try {
    const response = await api.post(`/user/password-reset/send`, { password, token })
    if (response.status === 200) {
      return true
    } else {
      return null
    }
  } catch (e) {
    return null
  }
}

export {
  setLocalToken,
  getLocalToken,
  deleteLocalToken,
  get,
  login,
  create,
  update,
  resetPasswordRequest,
  checkResetPasswordToken,
  resetPassword,
}
