import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import pathToTitle from '../utils/pathToTitle'

const SharingRoute = ({ component: Component, ...rest }) => {
  useEffect(() => {
    pathToTitle(rest.path)
    console.log('useEffect PrivateRoute')
  }, [rest.path])

  return <Route {...rest} component={(props) => <Component {...props} />} />
}

export { SharingRoute as default }
