import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import Recipe from '../components/Recipe/Recipe'
import RecipeShared from '../components/RecipeShared/RecipeShared'
import RecipeList from '../components/RecipeList/RecipeList'
import RecipeEdit from '../components/RecipeEdit/RecipeEdit'
import RecipeImport from '../components/RecipeImport/RecipeImport'
import SigninPage from '../components/Authentication/SigninPage'
import SignupPage from '../components/Authentication/SignupPage'
import PasswordRecoveryPage from '../components/Authentication/PasswordRecoveryPage'
import Settings from '../components/Settings/Settings'
import OnboardingIntro from '../components/Onboarding/OnboardingIntro'
import OnboardingImport from '../components/Onboarding/OnboardingImport'
import ErrorPage404 from '../components/ErrorPage404'
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import SharingRoute from './SharingRoute'

const AppRouter = () => {
  return (
    <ToastProvider placement="top-center" autoDismiss={true}>
      <Switch>
        <PrivateRoute path="/" exact={true} component={RecipeList} />
        <PrivateRoute path="/recipes/edit/" exact={true} component={RecipeEdit} />
        <PrivateRoute path="/recipes/edit/:id" component={RecipeEdit} />
        <PrivateRoute path="/recipes/:id" component={Recipe} />
        <PrivateRoute path="/import" component={RecipeImport} />
        <PrivateRoute path="/settings" component={Settings} />
        <SharingRoute path="/shared/:shortid" component={RecipeShared} />
        <PublicRoute path="/login" component={SigninPage} />
        <PublicRoute path="/register" component={SignupPage} />
        <PublicRoute path="/password-recovery" component={PasswordRecoveryPage} />
        <PrivateRoute path="/welcome-step-1" nav={false} component={OnboardingIntro} />
        <PrivateRoute path="/welcome-step-2" nav={false} component={OnboardingImport} />
        <Route component={ErrorPage404} />
      </Switch>
    </ToastProvider>
  )
}

export default AppRouter
