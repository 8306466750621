import React from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import AuthProvider from './auth-context'

function AppProviders({ children }) {
  const history = createBrowserHistory() // or createHashHistory()

  return (
    <Router history={history}>
      <AuthProvider>{children}</AuthProvider>
    </Router>
  )
}

export { AppProviders as default }
