import React from 'react'
import Sidebar from './Sidebar'
import Tabbar from './Tabbar'

export const PrivatePageLayout = ({ children }) => {
  const nav = children.props.nav === undefined ? true : children.props.nav
  return (
    <>
      {nav && (
        <>
          <div className="app__sidebar">
            <Sidebar />
          </div>
          <div className="app__tabbar">
            <Tabbar />
          </div>
        </>
      )}
      <div className={`app__content ${nav ? 'app__content--has-sidebar' : ''}`}>{children}</div>
    </>
  )
}

export { PrivatePageLayout as default }
