import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import { useAuth } from '../../context/auth-context'
import * as userClient from '../../utils/api/user-client'
import { addSharedRecipe } from '../../utils/api/recipe-client'
import TextField from '@material-ui/core/TextField'
import InputPassword from '../Common/InputPassword'

const SignupForm = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { login } = useAuth()
  const { register, handleSubmit, errors } = useForm()

  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = async (formData) => {
    setLoading(true)
    const result = await userClient.create(formData)
    if (result) {
      const token = await userClient.login(formData)
      if (token) {
        login(token)
        const recipeId = await addSharedRecipe()
        if (!recipeId) {
          history.push('/')
        } else {
          history.push({ pathname: `/recipes/${recipeId}`, state: { recipeSharedAdded: true } })
        }
      } else {
        setLoading(false)
        setErrorMessage('An error occured. Please try again later.')
      }
    } else if (result === false) {
      setLoading(false)
      setErrorMessage(
        <>
          <Trans i18nKey="error.email_exists">
            Sorry, there is already an account with this email.{' '}
            <Link to={{ pathname: `/login/`, state: { email: formData.email } }}>Log in</Link>
          </Trans>
        </>,
      )
    } else {
      setLoading(false)
      setErrorMessage('An error occured. Please try again later.')
    }
  }

  return (
    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
      {errorMessage && (
        <article className="message is-danger">
          <div className="message-body">{errorMessage}</div>
        </article>
      )}

      <div className="field">
        <div className="control has-icons-left">
          <TextField
            label={t('Email')}
            name="email"
            variant="outlined"
            size="small"
            fullWidth={true}
            className=""
            inputRef={register({
              required: {
                value: true,
                message: t('Please provide an email to login'),
              },
              pattern: {
                value: /^.+@[^.].*\.[a-z]{2,}$/i,
                message: t('This is not a valid email address'),
              },
            })}
            inputProps={{
              autoCapitalize: 'none',
              autoCorrect: 'off',
            }}
          />
          <span className="icon is-small is-left">
            <i className="fas fa-envelope"></i>
          </span>
        </div>
        {errors.email && <p className="help is-danger">{errors.email.message}</p>}
      </div>
      <div className="field">
        <InputPassword
          variant="outlined"
          size="small"
          className=""
          inputRef={register({
            required: {
              value: true,
              message: t("You didn't give any password !"),
            },
            minLength: {
              value: 8,
              message: t('Password must have at least 8 characters'),
            },
          })}
          fullWidth={true}
        />
        {errors.password && <p className="help is-danger">{errors.password.message}</p>}
      </div>
      <div className="field">
        <button
          type="submit"
          className={`button is-rounded is-link ${loading ? 'is-loading' : ''}`}>
          {t('Create a free account')}
        </button>
      </div>
    </form>
  )
}

export { SignupForm as default }
