import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { useForm } from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import RecipeUploadPhoto from './RecipeUploadPhotos'

import {
  instructionsToStr,
  strToInstructions,
  indexToTimeArray,
  valueToTimeArray,
} from '../../utils/helpers'
import {
  getRecipe,
  createRecipe,
  updateRecipe,
  saveRecipePhotos,
} from '../../utils/api/recipe-client'
import TextField from '@material-ui/core/TextField'
import RecipeEditToolbar from './RecipeEditToolbar'
import RecipeEditModal from './RecipeEditModal'

// NOTES
// react hook form
// https://react-hook-form.com/get-started

const RecipeEdit = () => {
  let { id } = useParams()
  let location = useLocation()
  let history = useHistory()
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [helpModalIsOpen, setHelpModalIsOpen] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [photos, setPhotos] = useState([])

  const { register, handleSubmit, setValue, errors } = useForm()

  const { addToast } = useToasts()

  // Submit on cmd+enter or ctrl+enter
  const onKeyDown = (e) => {
    if (e.keyCode === 13 && (e.metaKey || e.ctrlKey)) {
      e.preventDefault()
      handleSubmit(onSubmit)()
    }
  }

  const onSubmit = async (formData) => {
    let data = {}
    data.title = formData.title
    data.description = formData.description
    data.servings = formData.servings
    data.time = {}
    if (formData.prepTime) data.time.prepTime = indexToTimeArray(formData.prepTime).value
    if (formData.cookTime) data.time.cookTime = indexToTimeArray(formData.cookTime).value
    if (formData.totalTime) data.time.totalTime = indexToTimeArray(formData.totalTime).value
    data.ingredients = strToInstructions(formData.ingredients)
    data.instructions = strToInstructions(formData.instructions)

    setLoading(true)
    let error = false

    if (id) {
      const result = await updateRecipe(id, data)
      if (!result) {
        error = true
      }
    } else {
      id = await createRecipe(data)
      if (!id) {
        error = true
      }
    }

    if (!error && photos.length) {
      data = photos.map((photo) => photo.filename).filter((photo) => photo !== '')
      if (data.length) {
        await saveRecipePhotos(id, data)
      }
    }

    if (!error) {
      history.push(`/recipes/${id}`)
    } else {
      setLoading(false)
      // prettier-ignore
      addToast(
        <Trans i18nKey="error.network_unspecific">
          <strong>Sorry but something went wrong 😦</strong><div>Maybe try a little bit later</div>
        </Trans>,
        { appearance: 'error' },
      )
    }
  }

  // Configure time sliders
  const [prepTime, setPrepTime] = useState(indexToTimeArray(0))
  const handlePrepTime = (e) => {
    const prepTime = indexToTimeArray(e.target.value)
    setPrepTime(prepTime)
    setTotalTime(valueToTimeArray(prepTime.value + cookTime.value))
  }

  const [cookTime, setCookTime] = useState(indexToTimeArray(0))
  const handleCookTime = (e) => {
    const cookTime = indexToTimeArray(e.target.value)
    setCookTime(cookTime)
    setTotalTime(valueToTimeArray(cookTime.value + prepTime.value))
  }

  const [totalTime, setTotalTime] = useState(indexToTimeArray(0))
  const handleTotalTime = (e) => {
    setTotalTime(indexToTimeArray(e.target.value))
  }

  // useEffect
  useEffect(() => {
    // set scroll postion to top
    window.scrollTo(0, 0)

    // Prefill the form with the recipe
    setLoading(false)

    const fetchData = async () => {
      let recipe

      if (location.state && location.state.recipe && Object.keys(location.state.recipe).length) {
        recipe = location.state.recipe
      }

      if (recipe === undefined && id) {
        recipe = await getRecipe(id)
      }

      if (recipe !== undefined) {
        // init form values
        setValue([
          { title: recipe.title },
          { description: recipe.description },
          { servings: recipe.servings },
          { ingredients: instructionsToStr(recipe.ingredients) },
          { instructions: instructionsToStr(recipe.instructions, 2) },
        ])
        if (recipe.hasOwnProperty('time')) {
          setPrepTime(valueToTimeArray(recipe.time.prepTime))
          setCookTime(valueToTimeArray(recipe.time.cookTime))
          setTotalTime(valueToTimeArray(recipe.time.totalTime))
        }
        // init photos
        if (recipe.photos.length) {
          setPhotos([{ url: recipe.photos[0].photoThumbUrl, filename: '' }])
        }
      }
    }
    fetchData()
  }, [id, location.state, setValue])

  return (
    <div className="recipe-edit has-toolbar">
      <RecipeEditToolbar loading={loading} />

      <div className="default-layout">
        <div className="default-layout__wrap">
          <section>
            <form
              className="material-form-filled"
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={onKeyDown}
              id="formRecipeEdit">
              <div className="field">
                <div className="control">
                  <TextField
                    label={t('Title')}
                    name="title"
                    placeholder={t('A nice title for your recipe')}
                    variant="filled"
                    size="small"
                    fullWidth={true}
                    autoComplete="off"
                    className="recipe-edit__title"
                    inputRef={register({ required: true })}
                  />
                </div>
                {errors.title && (
                  <p className="help is-danger">
                    {t('A recipe without a title is not really a recipe')}
                  </p>
                )}
              </div>

              <div className="field">
                <div className="control">
                  <TextField
                    label={t('Description')}
                    placeholder={t('A short description if needed')}
                    name="description"
                    variant="filled"
                    size="small"
                    multiline
                    fullWidth={true}
                    autoComplete="off"
                    className="recipe-edit__description"
                    inputRef={register()}
                  />
                </div>
              </div>

              <RecipeUploadPhoto recipeId={id} photos={photos} setPhotos={setPhotos} />

              <div className="field">
                <div className="columns">
                  <div className="field column">
                    <div className="columns is-gapless is-mobile">
                      <label className="column label">{t('Prep time')}</label>
                      <output className="" htmlFor="prepTime">
                        {prepTime.label}
                      </output>
                    </div>
                    <div className="control">
                      <input
                        className="slider is-fullwidth is-medium is-circle"
                        step="1"
                        min="0"
                        max="120"
                        type="range"
                        name="prepTime"
                        value={prepTime.index}
                        onChange={handlePrepTime}
                        ref={register()}
                      />
                    </div>
                  </div>

                  <div className="field column">
                    <div className="columns is-gapless is-mobile">
                      <label className="column label">{t('Cook time')}</label>
                      <output className="" htmlFor="cookTime">
                        {cookTime.label}
                      </output>
                    </div>
                    <div className="control">
                      <input
                        className="slider is-fullwidth is-medium is-circle"
                        step="1"
                        min="0"
                        max="120"
                        type="range"
                        name="cookTime"
                        value={cookTime.index}
                        onChange={handleCookTime}
                        ref={register()}
                      />
                    </div>
                  </div>

                  <div className="field column">
                    <div className="columns is-gapless is-mobile">
                      <label className="column label">{t('Total time')}</label>
                      <output className="" htmlFor="totalTime">
                        {totalTime.label}
                      </output>
                    </div>
                    <div className="control">
                      <input
                        className="slider is-fullwidth is-medium is-circle"
                        step="1"
                        min="0"
                        max="120"
                        type="range"
                        name="totalTime"
                        value={totalTime.index}
                        onChange={handleTotalTime}
                        ref={register()}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="field  is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <TextField
                        label={t('Servings')}
                        placeholder={t('How many persons')}
                        variant="filled"
                        size="small"
                        type="number"
                        autoComplete="off"
                        className=""
                        inputRef={register()}
                        name="servings"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <TextField
                    label={t('Ingredients')}
                    name="ingredients"
                    placeholder={t('What will we put in that recipe')}
                    variant="filled"
                    size="small"
                    multiline
                    fullWidth={true}
                    autoComplete="off"
                    className="recipe-edit__ingredients"
                    inputRef={register()}
                  />
                  <span
                    className="tag is-dark recipe-edit__help-button"
                    onClick={() => {
                      setModalType('ingredients')
                      setHelpModalIsOpen(true)
                    }}>
                    {t('Help')}
                  </span>
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <TextField
                    label={t('Instructions')}
                    name="instructions"
                    placeholder={t('How the magic will be prepared')}
                    variant="filled"
                    size="small"
                    multiline
                    fullWidth={true}
                    autoComplete="off"
                    className="recipe-edit__instructions"
                    inputRef={register()}
                  />
                  <span
                    className="tag is-dark is-light recipe-edit__help-button"
                    onClick={() => {
                      setModalType('instructions')
                      setHelpModalIsOpen(true)
                    }}>
                    {t('Help')}
                  </span>
                </div>
              </div>
            </form>
          </section>
        </div>
      </div>
      <RecipeEditModal
        modalType={modalType}
        modalIsOpen={helpModalIsOpen}
        closeModal={() => setHelpModalIsOpen(false)}
      />
    </div>
  )
}

export { RecipeEdit as default }
