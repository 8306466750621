import React, { useState, useMemo } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { formatTime, saveScrollPosition } from '../../utils/helpers'
import { addFavorite, deleteFavorite } from '../../utils/api/recipe-client'
import Image from '../Image'

const RecipeListItem = ({ recipe }) => {
  let location = useLocation()
  let history = useHistory()
  const id = recipe.id

  const [isFavorite, setIsFavorite] = useState(Boolean(recipe.userFavorite))

  const toggleFavorite = (e) => {
    //e.preventDefault()
    if (isFavorite) deleteFavorite(id)
    else addFavorite(id)
    setIsFavorite(!isFavorite)
  }

  const goToRecipe = (e) => {
    e.stopPropagation()
    if (e.target.className !== 'recipe-table-item__link') {
      saveScrollPosition()
      history.push(`/recipes/${recipe.id}`)
    }
  }

  const photoSrc = useMemo(() => {
    if (recipe.photos && recipe.photos[0]) {
      return recipe.photos[0].photoThumbUrl
    } else {
      return `/images/placeholder-1200x800-${recipe.id % 4}.png`
    }
  }, [recipe.id, recipe.photos])

  return (
    <tr className="recipe-table-item">
      <td className="recipe-table-item__td-img is-hidden-mobile" onClick={goToRecipe}>
        <Image src={photoSrc} width="600" height="338" alt={recipe.title} className="img" />
      </td>
      <td className="recipe-table-item__td-title" onClick={goToRecipe}>
        <Link
          className="recipe-table-item__link"
          onClick={saveScrollPosition}
          to={{ pathname: `/recipes/${recipe.id}`, state: { recipe, from: location.pathname } }}>
          {recipe.title}
        </Link>
      </td>
      <td className="recipe-table-item__td-favorite">
        {isFavorite ? (
          <button className="recipe-table-item__favorite">
            <span
              className="icon has-text-primary"
              key="is-favorite"
              onClick={toggleFavorite.bind(this)}>
              <i className="fas fa-heart"></i>
            </span>
          </button>
        ) : (
          <button className="recipe-table-item__favorite">
            <span className="icon" key="is-not-favorite" onClick={toggleFavorite.bind(this)}>
              <i className="far fa-heart"></i>
            </span>
          </button>
        )}
      </td>
      <td className="recipe-table-item__td-time" onClick={goToRecipe}>
        {!!recipe.time && !!recipe.time.totalTime && (
          <span className="tag is-dark recipe-table-item__time">
            <i className="far fa-clock"></i>
            <span>&nbsp;</span>
            {formatTime(recipe.time.totalTime, 's')}
          </span>
        )}
      </td>
    </tr>
  )
}

export { RecipeListItem as default }
