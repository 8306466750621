import React from 'react'
import Modal from 'react-modal'
import { useTranslation, Trans } from 'react-i18next'

const RecipeEditModal = ({ modalType, modalIsOpen, closeModal }) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={!!modalIsOpen}
      onRequestClose={closeModal}
      closeTimeoutMS={200}
      contentLabel="Selected Option"
      className="modal is-active recipe-edit__help-modal">
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {modalType === 'ingredients' ? t('Format Ingredients') : t('Format Instructions')}
          </p>
          <button className="delete" aria-label="close" onClick={closeModal}></button>
        </header>
        <section className="modal-card-body">
          {modalType === 'ingredients' ? (
            <Trans i18nKey="format_ingredients">
              <>
                <div className="message is-primary">
                  <div className="message-body">
                    <div className="content">
                      Use one line for each Ingredients
                      <br />
                      To create a subtitle, end a line with a ":"
                    </div>
                  </div>
                </div>
                <textarea className="textarea" disabled value={t('format_ingredients_example')} />
              </>
            </Trans>
          ) : (
            <Trans i18nKey="format_instructions">
              <>
                <div className="message is-primary">
                  <div className="message-body">
                    <div className="content">
                      Use one line for each Instructions
                      <br />
                      To create a subtitle, end a line with a ":"
                    </div>
                  </div>
                </div>
                <textarea className="textarea" disabled value={t('format_instructions_example')} />
              </>
            </Trans>
          )}
        </section>
        <footer className="modal-card-foot">
          <button className="button" onClick={closeModal}>
            {t('Close')}
          </button>
        </footer>
      </div>
    </Modal>
  )
}

export default RecipeEditModal
