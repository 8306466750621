import React, { useEffect, useState, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import RecipeSharedToolbar from './RecipeSharedToolbar'
import RecipePhotos from '../Recipe/RecipePhotos'
import RecipeIntro from '../Recipe/RecipeIntro'
import RecipeIngredients from '../Recipe/RecipeIngredients'
import RecipeInstructions from '../Recipe/RecipeInstructions'
import FullPageLoader from '../FullPageLoader'
import Recipe404 from '../Recipe/Recipe404'
import { getSharedRecipe } from '../../utils/api/recipe-client'
import { useAuth } from '../../context/auth-context'

const RecipeShared = (props) => {
  const { shortid } = useParams()
  const { user } = useAuth()
  const history = useHistory()

  const [recipe, setRecipe] = useState({})
  const [noRecipe, setNoRecipe] = useState(false)
  const [loading, setLoading] = useState(true)
  const isMounted = useRef(null)

  useEffect(() => {
    // page title
    if (recipe && recipe.title) {
      document.title = document.title.replace('$recipe', recipe.title)
    }
  })

  useEffect(() => {
    // set scroll postion to top
    window.scrollTo(0, 0)

    const fetchData = async () => {
      //setLoading(true)
      // isMounted avoids to send a request if the component has been unmounted
      isMounted.current = true
      setLoading(true)
      const data = await getSharedRecipe(shortid)
      if (isMounted.current) {
        if (!data) {
          setNoRecipe(true)
        } else if (data.userId === user.id) {
          history.push(`/recipes/${data.id}`)
        }
        setRecipe(data)
        setLoading(false)
      }
    }
    fetchData()
    return () => (isMounted.current = false)
  }, [shortid, history, user.id])

  // Image placeholder at loading
  // https://itnext.io/stable-image-component-with-placeholder-in-react-7c837b1ebee

  return (
    <>
      {!noRecipe ? (
        <>
          {!loading ? (
            <>
              <RecipeSharedToolbar id={recipe.id} recipe={recipe} />
              <div className="recipe has-toolbar">
                <section className="recipe__header columns is-variable is-4">
                  <div className="column recipe__column--photo">
                    <RecipePhotos id={recipe.id ? recipe.id : 0} recipe={recipe} />
                  </div>
                  <div className="column">
                    <RecipeIntro recipe={recipe} />
                  </div>
                </section>

                <section className="recipe__content columns is-variable is-4">
                  <div className="column recipe__column--ingredients">
                    <RecipeIngredients recipe={recipe} />
                  </div>
                  <div className="column">
                    <RecipeInstructions recipe={recipe} />
                  </div>
                </section>
              </div>
            </>
          ) : (
            <FullPageLoader />
          )}
        </>
      ) : (
        <Recipe404 />
      )}
    </>
  )
}

export { RecipeShared as default }
