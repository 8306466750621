import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'
import i18n from 'i18next'

import * as userClient from '../../utils/api/user-client'
import { useTranslation, Trans } from 'react-i18next'
import TextField from '@material-ui/core/TextField'

const SigninForm = ({ showResetPassword }) => {
  const location = useLocation()
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { register, handleSubmit, setValue, errors } = useForm()

  const [requestSent, setRequestSent] = useState(false)
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = async (formData) => {
    setLoading(true)
    const result = await userClient.resetPasswordRequest(formData.email, i18n.language.substr(0, 2))
    if (result) {
      setEmail(formData.email)
      setRequestSent(true)
    } else {
      setLoading(false)
      addToast(
        <Trans i18nKey="error.network_unspecific">
          <strong>Sorry but something went wrong 😦</strong>
          <div>Maybe try a little bit later</div>
        </Trans>,
        { appearance: 'error' },
      )
    }
  }

  useEffect(() => {
    // Get email value to prefill email input
    if (location.state && location.state.email) {
      setValue([{ email: location.state.email }])
    }
  }, [location.state, setValue])

  return (
    <>
      {requestSent ? (
        <>
          <div>
            <article class="message is-success">
              <div class="message-body">{t('An email as just been sent', { email: email })}</div>
            </article>
            <div className="center  m-b-l">
              <span class="icon has-text-primary is-large">
                <i class="fas fa-3x fa-check-circle"></i>
              </span>
            </div>
          </div>
        </>
      ) : (
        <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="instructions">{t('')}</div>

          <div className="field">
            <div className="control has-icons-left">
              <TextField
                label={t('Email')}
                name="email"
                variant="outlined"
                size="small"
                fullWidth={true}
                className=""
                inputRef={register({
                  required: {
                    value: true,
                    message: t('Please provide an email to login'),
                  },
                  pattern: {
                    value: /^.+@[^.].*\.[a-z]{2,}$/i,
                    message: t('This is not a valid email address'),
                  },
                })}
                inputProps={{
                  autoCapitalize: 'none',
                  autoCorrect: 'off',
                }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-envelope"></i>
              </span>
            </div>
            {errors.email && <p className="help is-danger">{errors.email.message}</p>}
          </div>
          <div className="field margin-top-small">
            <button type="submit" className={`button is-link ${loading ? 'is-loading' : ''}`}>
              {t('Send me the instructions')}
            </button>
          </div>
        </form>
      )}
    </>
  )
}

export { SigninForm as default }
